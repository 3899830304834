import styles from "./customLoader.module.scss";

import { BiLoaderCircle } from "react-icons/bi";

const CustomLoader = ({ style, small }) => {
  return (
    <div className={[styles.container, small ? styles.small : null].join(" ")}>
      <BiLoaderCircle style={style} />
    </div>
  );
};

export default CustomLoader;
