import axios from "axios";
import { apiUrl } from "../configs/apiUri";

const getStripeSpaceUrl = async (accessToken) => {
  const { data } = await axios.get(`${apiUrl}/stripe/space-url`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data;
};

const getStripeSubcriptionUrl = async (accessToken) => {
  const { data } = await axios.get(`${apiUrl}/stripe/subscription-url`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data;
};

export { getStripeSpaceUrl, getStripeSubcriptionUrl };
