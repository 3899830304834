import HelpGoBack from "../HelpGoBack/HelpGoBack";
import styles from "./helpSalesApproach.module.scss";

// ** Icon **
import { BiTargetLock } from "react-icons/bi";
import { BiMailSend } from "react-icons/bi";
import { BiPhone } from "react-icons/bi";
import { FaRegHandshake } from "react-icons/fa";
import { MdWavingHand } from "react-icons/md";
import { BiQuestionMark } from "react-icons/bi";
import { BiCommentCheck } from "react-icons/bi";
import { BsCardChecklist } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { GoChecklist } from "react-icons/go";
import { BiUser } from "react-icons/bi";

import { modelGenerationItem } from "data/modelGenerationItem";

const HelpSalesApproach = ({ changeIndex }) => {
  const Icon = [
    BiTargetLock,
    BiUser,
    BiMailSend,
    FaLinkedinIn,
    BiPhone,
    MdWavingHand,
    BiQuestionMark,
    BsCardChecklist,
    BiCommentCheck,
    GoChecklist,
    FaRegHandshake,
  ];

  return (
    <div className={styles.container}>
      <HelpGoBack changeIndex={() => changeIndex(1)} />

      <h4>La démarche de vente</h4>

      <p>
        Sales Copilot se base sur la démarche de vente en 6 étapes et vous accompagne dans chacuns d'elles ainsi que sur
        la préparation en amont.
      </p>

      <h5>Avant la vente</h5>
      <p>
        Le ciblage client consiste à choisir les prospects qui sont les plus susceptibles d'être intéressées par votre
        produit ou service, pour que vous puissiez concentrer vos efforts sur elles.
      </p>
      <div className={[styles.itemContainer, styles.itemContainer__flexStart].join(" ")}>
        {modelGenerationItem.map((item, index) => {
          const IconComponent = Icon[index];

          return (
            (index === 0 || index === 1) && (
              <div className={styles.item} key={index}>
                <div className={styles.icon} style={{ color: item.color }}>
                  <IconComponent />
                  <p>{item.name}</p>
                </div>
              </div>
            )
          );
        })}
      </div>

      <h5>La prise de rendez-vous</h5>
      <p>
        Prendre rendez-vous avec vos prospects est crucial pour établir une relation de confiance et démontrer votre
        engagement envers eux.
      </p>
      <div className={styles.itemContainer}>
        {modelGenerationItem.map((item, index) => {
          const IconComponent = Icon[index];

          return (
            index > 1 &&
            index < 5 && (
              <div className={styles.item} key={index}>
                <div className={styles.icon} style={{ color: item.color }}>
                  <IconComponent />
                  <p>{item.name}</p>
                </div>
              </div>
            )
          );
        })}
      </div>

      <h5>Durant la vente</h5>
      <p>
        Une bonne démarche de vente vous permet de mieux comprendre les besoins de votre client potentiel, de répondre à
        leurs questions et de présenter votre offre de manière convaincante. Ce qui permet d'augmenter drastiquement vos
        chances de conclure une vente et de fidéliser votre clientèle.
      </p>
      <div className={styles.itemContainer}>
        {modelGenerationItem.map((item, index) => {
          const IconComponent = Icon[index];

          return (
            index > 4 && (
              <div className={styles.item} key={index}>
                <div className={styles.icon} style={{ color: item.color }}>
                  <IconComponent />
                  <p>{item.name}</p>
                </div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default HelpSalesApproach;
