import { BrowserRouter as Router } from "react-router-dom";

import Routing from "routes/routing";

// ** Providers **
import AuthContextProvider from "context/authContext";
import ErrorModaleProvider from "context/errorModale";
import UserStatutProvider from "context/userStatutContext";
import NumberOfCreationProvider from "context/numberOfCreationContext";

function App() {
  return (
    <Router>
      <AuthContextProvider>
        <ErrorModaleProvider>
          <UserStatutProvider>
            <NumberOfCreationProvider>
              <Routing />
            </NumberOfCreationProvider>
          </UserStatutProvider>
        </ErrorModaleProvider>
      </AuthContextProvider>
    </Router>
  );
}

export default App;
