import { createContext } from "react";
import { useQuery } from "react-query";

// ** Controllers **
import { fetchProducts } from "controllers/product.controller";

// ** Hooks **
import useAccessToken from "hooks/useAccessToken";
import useErrorModale from "hooks/useErrorModale";

export const UserProductContext = createContext();

const UserProductProvider = ({ children }) => {
  const accessToken = useAccessToken();
  const { setErrorModaleName } = useErrorModale();

  const {
    data: userProductData,
    isLoading: userProductLoading,
    error: userProductError,
    refetch: userProductRefetch,
  } = useQuery(["products", accessToken], () => fetchProducts(accessToken?.userToken), {
    enabled: !!accessToken && !!accessToken?.userToken,
    onError: (error) => {
      if (error?.response?.status === 401) {
        setErrorModaleName("session_expired");
      }
    },
  });

  return (
    <UserProductContext.Provider value={{ userProductData, userProductLoading, userProductError, userProductRefetch }}>
      {children}
    </UserProductContext.Provider>
  );
};

export default UserProductProvider;
