import { useState } from "react";

import useAccessToken from "hooks/useAccessToken";
import { getStripeSpaceUrl, getStripeSubcriptionUrl } from "controllers/stripe.controllers";

const useGetStripeUrl = () => {
  const { userToken } = useAccessToken();
  const [isLoading, setIsLoading] = useState(false);

  const openStripeSpaceUrl = async () => {
    setIsLoading(true);
    const stripeUrl = await getStripeSpaceUrl(userToken);

    setIsLoading(false);
    window.location.replace(stripeUrl?.url);
  };

  const openStripeSubscriptionUrl = async () => {
    setIsLoading(true);
    const stripeUrl = await getStripeSubcriptionUrl(userToken);

    setIsLoading(false);
    window.location.replace(stripeUrl?.url);
  };

  return { openStripeSpaceUrl, openStripeSubscriptionUrl, isLoading };
};

export default useGetStripeUrl;
