import styles from "./or.module.scss";

const Or = () => {
  return (
    <div className={styles.container}>
      <div></div>
      <p>ou</p>
      <div></div>
    </div>
  );
};

export default Or;
