import axios from "axios";
import { apiUrl } from "../configs/apiUri";

const fetchHistory = async (accessToken, limit = null) => {
  const url = limit ? `${apiUrl}/user-history/by-user-id?limit=${limit}` : `${apiUrl}/user-history/by-user-id`;

  const { data } = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data;
};

const getCountHistory = async (accessToken) => {
  const { data } = await axios.get(`${apiUrl}/user-history/count`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data;
};

const deleteHistoryById = async (accessToken, historyId, refetch) => {
  const { data } = await axios.delete(`${apiUrl}/user-history/${historyId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  refetch();
  return data;
};

export { fetchHistory, deleteHistoryById, getCountHistory };
