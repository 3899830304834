import Cookies from "js-cookie";

import useAccessToken from "hooks/useAccessToken";

const useDeconnexion = () => {
  const { setTokenChange } = useAccessToken();

  const deconnexion = () => {
    Cookies.remove("_access");
    setTokenChange((prev) => !prev);
  };

  return { deconnexion };
};

export default useDeconnexion;
