import useErrorModale from "hooks/useErrorModale";

import ErrorConnexionModale from "./ErrorConnexionModale/ErrorConnexionModale";

import ModaleWrapper from "../ModaleWrapper/ModaleWrapper";

const ErrorModales = () => {
  const { errorModaleName } = useErrorModale();

  let ModaleContainer;

  switch (errorModaleName) {
    case "session_expired":
      ModaleContainer = <ErrorConnexionModale />;
      break;
    default:
      break;
  }

  return errorModaleName ? <ModaleWrapper>{ModaleContainer}</ModaleWrapper> : <div></div>;
};

export default ErrorModales;
