import { useEffect, useState } from "react";
import styles from "./header.module.scss";
import { menuItemList } from "data/menuItemList";
import MenuItem from "./MenuItem/MenuItem";

import { BiChevronLeft } from "react-icons/bi";
import { BiHelpCircle } from "react-icons/bi";
import { MdOutlineUpgrade } from "react-icons/md";

import { ReactComponent as Icon } from "assets/pictures/sales_colpilot_logo.svg";

import CustomButton from "components/BasicComponents/FormsComponents/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import useUserStatut from "hooks/useUserStatut";

const Header = ({ setIsHelpModale }) => {
  const { userStatut } = useUserStatut();
  const navigate = useNavigate();
  const isOpenSidebar = localStorage.getItem("isOpenSidebar");
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(isOpenSidebar === "false" ? false : true);
  }, [isOpenSidebar]);

  return (
    <div className={[styles.container, !isOpen ? styles.container__close : null].join(" ")}>
      <BiChevronLeft
        className={[styles.chevron, !isOpen ? styles.chevron__right : null].join(" ")}
        onClick={() => {
          localStorage.setItem("isOpenSidebar", !isOpen);
          setIsOpen(!isOpen);
        }}
      />

      <div className={styles.boxLogo}>
        <Icon className={styles.logo} />
        <span className={styles.box__name}>SalesCopilot</span>
        <span>Beta</span>
      </div>

      <nav>
        <ul className={[styles.menu, isOpen ? styles.menu__close : null].join(" ")}>
          {menuItemList.map((item, index) => {
            return <MenuItem key={index} item={item} index={index} />;
          })}
        </ul>
      </nav>

      {userStatut === "basic" && (
        <CustomButton
          display
          cssClass={styles.billingButton}
          content={isOpen ? "M'abonner" : <MdOutlineUpgrade />}
          onclick={() => navigate("/reglages?focus=abonnement")}
        />
      )}

      <div className={[styles.lastMenu, styles.box].join(" ")} onClick={() => setIsHelpModale(true)}>
        <BiHelpCircle />
        <span>Aide</span>
      </div>
    </div>
  );
};

export default Header;
