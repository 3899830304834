import styles from "./homeCard.module.scss";

import { IoFileTrayFull } from "react-icons/io5";

const HomeCard = ({ title, number }) => {
  return (
    <div className={styles.container}>
      <IoFileTrayFull />
      <div>
        <p>{number}</p>
        <p>{title}</p>
      </div>
    </div>
  );
};

export default HomeCard;
