export const modelGenerationItem = [
  {
    name: "Ciblage clients",
    link: "ciblage-clients",
    categorie: "cibler",
    emoji: "🎯",
    bgColor: "linear-gradient(125deg, #e62c6d 0%, #FF327A 100%)",
    boxShadow: "0px 3px 4px rgba(230, 44, 109, 0.2)",
    color: "#FF354E",
    description:
      "Obtenez des recommandations personnalisées de typologies de clients les plus susceptibles d'être intéressées par votre produit ou service.",
  },
  {
    name: "Définir un persona",
    link: "persona-prospect",
    categorie: "cibler",
    bgColor: "linear-gradient(125deg, #e62c6d 0%, #FF327A 100%)",
    boxShadow: "0px 3px 4px rgba(230, 44, 109, 0.2)",
    color: "#FF354E",
    description:
      "Comprenez vos prospects idéaux en définissant leurs attentes, leurs habitudes et leurs besoins, pour mieux adapter votre stratégie.",
  },
  {
    name: "Email de prospection",
    link: "email-prospection",
    categorie: "prospecter",
    emoji: "✉️",
    bgColor: "linear-gradient(125deg, #00a56e 0%, #01B578 100%)",
    boxShadow: "0px 3px 4px rgba(0, 165, 110, 0.2)",
    color: "#08D469",
    description:
      "Créez des e-mails percutants qui captent l'attention de vos destinataires et augmentez drastiquement votre taux de réponse.",
  },
  {
    name: "Message privé Linkedin",
    link: "message-prive-linkedin",
    categorie: "prospecter",
    emoji: "✉️",
    bgColor: "linear-gradient(125deg, #00a56e 0%, #01B578 100%)",
    boxShadow: "0px 3px 4px rgba(0, 165, 110, 0.2)",
    color: "#08D469",
    description:
      "Boostez votre réseaux en envoyant des messages privés qui attirent l'attention de vos prospects et les incitent à interagir avec vous.",
  },
  {
    name: "Pitch téléphonique",
    link: "pitch-telephonique",
    categorie: "prospecter",
    emoji: "📞",
    bgColor: "linear-gradient(125deg, #00a56e 0%, #01B578 100%)",
    boxShadow: "0px 3px 4px rgba(0, 165, 110, 0.2)",
    color: "#08D469",
    description:
      "Maîtrisez l'art du pitch téléphonique, impactant et efficace, pour suciter l'attention de votre intérlocuteur en un rien de temps.",
  },
  {
    name: "Prise de contact",
    link: "contact",
    categorie: "vendre",
    emoji: "👋",
    bgColor: "linear-gradient(125deg, #1983e1 0%, #1C90F4 100%)",
    boxShadow: "0px 3px 4px rgba(76, 171, 224, 0.2)",
    color: "#4cabe0",
    description:
      "Optimisez vos premiers échanges en face à face et augmenter l'interêt de vos clients potentiels pour votre produit ou service.",
  },
  {
    name: "Recueil des besoins",
    link: "recueil",
    categorie: "vendre",
    emoji: "❓",
    bgColor: "linear-gradient(125deg, #1983e1 0%, #1C90F4 100%)",
    boxShadow: "0px 3px 4px rgba(76, 171, 224, 0.2)",
    color: "#4cabe0",
    description:
      "Trouvez les bonnes questions à poser à vos prospects pour mieux les connaître et mieux cibler vos arguments.",
  },
  {
    name: "Arguments à la volée",
    link: "arguments-a-la-volee",
    categorie: "vendre",
    emoji: "📝",
    bgColor: "linear-gradient(125deg, #1983e1 0%, #1C90F4 100%)",
    boxShadow: "0px 3px 4px rgba(76, 171, 224, 0.2)",
    color: "#4cabe0",
    description:
      "Générez une liste d'arguments indéniables pour votre produit/service liés aux intérets de vos prospects.",
  },
  {
    name: "Argumentaire",
    link: "",
    categorie: "vendre",
    emoji: "🗣️",
    bgColor: "linear-gradient(125deg, #1983e1 0%, #1C90F4 100%)",
    boxShadow: "0px 3px 4px rgba(76, 171, 224, 0.2)",
    color: "#4cabe0",
    description:
      "Obtenez des arguments de vente sur-mesure en lien avec la situation de votre prospect et augmentez ainsi votre taux de transformation.",
  },
  {
    name: "Tableau d'objections",
    link: "tableau-d-objections",
    categorie: "vendre",
    bgColor: "linear-gradient(125deg, #1983e1 0%, #1C90F4 100%)",
    boxShadow: "0px 3px 4px rgba(76, 171, 224,  0.2)",
    color: "#4cabe0",
    description:
      "Anticiper les objections que vous pourrez recevoir et préparez des réponses adaptées pour ne plus être pris au dépourvu.",
  },
  {
    name: "Réponse à une objection",
    link: "repondre-a-une-objection",
    categorie: "vendre",
    emoji: "💪",
    bgColor: "linear-gradient(125deg, #1983e1 0%, #1C90F4 100%)",
    boxShadow: "0px 3px 4px rgba(76, 171, 224,  0.2)",
    color: "#4cabe0",
    description:
      "Bloqué par une objection spécifique ? Trouvez la réponse adaptée la plus efficace pour y repondre facilement.",
  },
];
