const checkGptData = (datas, setErrorMessage) => {
  let dataObject = {};

  for (const data of datas) {
    const dataKey = Object.keys(data)[0];
    const dataValue = Object.values(data)[0];

    if (dataKey === "userProductSelected" && !dataValue && !Object.values(datas.find((item) => item.productToSale))) {
      setErrorMessage("Veuillez selectionner ou décrire ce que vous vendez pour une réponse efficace.");

      return;
    }

    if (
      dataKey === "productToSale" &&
      dataValue.length < 3 &&
      !Object.values(datas.find((item) => item.userProductSelected))
    ) {
      setErrorMessage("Veuillez selectionner ou décrire ce que vous vendez pour une réponse efficace.");

      return;
    }

    if (dataKey !== "productToSale" && dataKey !== "userProductSelected" && (!dataValue || dataValue.length < 3)) {
      setErrorMessage("Veuillez remplir tous les champs pour une réponse efficace.");

      return;
    }

    dataObject[Object.keys(data)[0]] = Object.values(data)[0];
  }

  return dataObject;
};

export default checkGptData;
