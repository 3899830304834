import axios from "axios";
import { apiUrl } from "../configs/apiUri";

const fetchProducts = async (accessToken) => {
  const { data } = await axios.get(`${apiUrl}/user-product`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data;
};

const submitNewProduct = async (accessToken, name, description, setErrorMessage) => {
  if (!name || !description) {
    return setErrorMessage("Veuillez remplir tous les champs");
  }

  const dataForm = {
    name,
    description,
  };

  const callServer = await axios.post(`${apiUrl}/user-product/`, dataForm, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return callServer;
};

const submitUpdateProduct = async (accessToken, name, description, produtId, setErrorMessage) => {
  if (!name || !description) {
    return setErrorMessage("Veuillez remplir tous les champs");
  }

  const dataForm = {
    name,
    description,
  };

  const callServer = await axios.put(`${apiUrl}/user-product/${produtId}`, dataForm, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return callServer;
};

const deleteProduct = async (accessToken, productId) => {
  const callServer = await axios.delete(`${apiUrl}/user-product/${productId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return callServer;
};

export { fetchProducts, submitNewProduct, submitUpdateProduct, deleteProduct };
