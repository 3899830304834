const billingData = [
  {
    name: "Basique",
    code: "basic",
    price: "Gratuit",
    subtitle: "Testez gratuitement toute la puissance de Sales Copilot",
    advantages: [
      { icon: "yes", content: "Accès à toute l'application" },
      { icon: "yes", content: "Accès à l'ensemble de votre historique" },

      { icon: "no", content: "Limité à 15 utilisations" },
    ],
  },
  {
    name: "Pro",
    code: "pro",
    price: "6,99€",
    subtitle: "Accédez en illimité à toute l'application, sans engagement",
    advantages: [
      { icon: "yes", content: "Accès à toute l'application" },
      { icon: "yes", content: "Accès à l'ensemble de votre historique" },
      { icon: "yes", content: "Nombre d'utilisations illimité" },
      { icon: "yes", content: "Prix de lancement exclusif et garantie à vie" },
    ],
  },
];

export default billingData;
