import { useState, useEffect } from "react";
import useAccessToken from "hooks/useAccessToken";
import useErrorModale from "hooks/useErrorModale";

import { submitNewProduct, submitUpdateProduct } from "controllers/product.controller";

import useUserProduct from "hooks/useUserProducts";

const useSubmitProductForm = (product, setIsOpenLeftPanel) => {
  const { userProductRefetch } = useUserProduct();

  const { userToken } = useAccessToken();
  const { setErrorModaleName } = useErrorModale();

  const [name, setName] = useState(product.name);
  const [description, setDescription] = useState(product.description);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setName(product.name);
    setDescription(product.description);
  }, [product]);

  const onSubmit = async (e) => {
    e.preventDefault();

    setErrorMessage("");
    setIsLoading(true);

    try {
      if (!product?._id) {
        await submitNewProduct(userToken, name, description, setErrorMessage);

        userProductRefetch();

        setName("");
        setDescription("");
        setIsOpenLeftPanel(null);
      } else {
        await submitUpdateProduct(userToken, name, description, product._id, setErrorMessage);

        userProductRefetch();
        setIsOpenLeftPanel(null);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        return setErrorModaleName("session_expired");
      }

      return setErrorMessage("Une erreur est survenue");
    }

    setIsLoading(false);
  };

  return { name, setName, description, setDescription, isLoading, onSubmit, errorMessage };
};

export default useSubmitProductForm;
