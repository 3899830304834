import styles from "./historyFolder.module.scss";

import { TbFolderFilled } from "react-icons/tb";

const HistoryFolder = ({ numberOfFiles, productName, onClick, focus }) => {
  return (
    <div className={[styles.container, focus ? styles.focus : null].join(" ")} onClick={onClick}>
      <div className={styles.folderTop}>
        <TbFolderFilled />
      </div>

      <div>
        <p>{productName}</p>
        <p>{numberOfFiles ? numberOfFiles : 0} fichiers</p>
      </div>
    </div>
  );
};

export default HistoryFolder;
