import styles from "./historyGridHeader.module.scss";

const HistoryGridHeader = () => {
  return (
    <div className={styles.HistoryGridHeader}>
      <div>
        <p>Categories</p>
        <p>Contenu</p>
        <p>Date</p>
      </div>

      <p> </p>
    </div>
  );
};

export default HistoryGridHeader;
