import checkEmail from "../checkMailFormat/checkMailFormat.js";

export const checkLogin = (emailUser, passwordUser, setErrorMessage) => {
  if (!emailUser || !passwordUser) {
    setErrorMessage("Veuillez remplir tous les champs pour vous connecter.");
    return false;
  }

  if (!checkEmail(emailUser)) {
    setErrorMessage("Veuillez entrer une adresse email valide.");
    return false;
  }

  if (passwordUser.length < 8) {
    setErrorMessage("Le mot de passe doit contenir au moins 8 caractères.");
    return false;
  }

  return true;
};
