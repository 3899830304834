import { useState } from "react";

import styles from "./passwordManagement.module.scss";

// ** Components **
import CustomButton from "components/BasicComponents/FormsComponents/CustomButton/CustomButton";
import CustomInput from "components/BasicComponents/FormsComponents/CustomInput/CustomInput";

// ** Hooks **
import useAccessToken from "hooks/useAccessToken";

// ** Controller **
import { updatePassword } from "controllers/user.controllers";

const PasswordManagement = () => {
  const { userToken } = useAccessToken();
  const [userOldPassword, setUserOldPassword] = useState("");
  const [userNewPassword, setUserNewPassword] = useState("");
  const [userNewPasswordConfirm, setUserNewPasswordConfirm] = useState("");
  const [message, setMessage] = useState(null);

  return (
    <div className={styles.wrapper}>
      <h3>Changer de mot de passe</h3>
      <h4>Changez votre mot de passe quand vous le souhaitez, pour un maximum de sécurité.</h4>

      <div className={styles.section}>
        <div className={styles.section__title}>
          <h5>Mot de passe</h5>
          <p>Modifier mon mot de passe</p>
        </div>
        <div className={styles.section__right}>
          <CustomInput
            type="password"
            label="Mot de passe actuel"
            value={userOldPassword}
            setValue={setUserOldPassword}
            onChange={(e) => setUserOldPassword(e.target.value)}
          />
          <CustomInput
            type="password"
            label="Nouveau mot de passe"
            value={userNewPassword}
            setValue={setUserNewPassword}
            onChange={(e) => setUserNewPassword(e.target.value)}
          />
          <CustomInput
            type="password"
            label="Confirmer le nouveau mot de passe"
            value={userNewPasswordConfirm}
            setValue={setUserNewPasswordConfirm}
            onChange={(e) => setUserNewPasswordConfirm(e.target.value)}
          />
          <CustomButton
            display={userOldPassword && userNewPassword && userNewPasswordConfirm ? true : false}
            content={"Mettre à jour"}
            style={{ width: "100%" }}
            onclick={async () => {
              const data = await updatePassword(
                userToken,
                userOldPassword,
                userNewPassword,
                userNewPasswordConfirm,
                setMessage
              );

              if (data) {
                setUserOldPassword("");
                setUserNewPassword("");
                setUserNewPasswordConfirm("");
              }
            }}
          />
          {message && (
            <p className={message?.type === "success" ? styles.successMessage : styles.errorMessage}>
              {message?.message}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PasswordManagement;
