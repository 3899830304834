import CustomButton from "components/BasicComponents/FormsComponents/CustomButton/CustomButton";
import styles from "./BillinCard.module.scss";

import { BiCheck } from "react-icons/bi";
import { BiX } from "react-icons/bi";

import useGetStripeUrl from "hooks/useGetStripeUrl";

const BillingCard = ({ billing, userStatut }) => {
  const { openStripeSubscriptionUrl, isLoading } = useGetStripeUrl();

  return (
    <div className={styles.billing}>
      <div className={styles.title}>
        <div>
          <h5>{billing.name}</h5>
          <p>{billing.subtitle}</p>
        </div>

        <p>
          {billing.price} {billing.name === "Pro" && <span>par mois</span>}
        </p>
      </div>

      <div className={styles.advantages}>
        {billing.advantages.map((advantage, index) => {
          return (
            <div key={index}>
              {advantage.icon === "yes" ? (
                <BiCheck className={styles.icon__yes} />
              ) : advantage.icon === "no" ? (
                <BiX className={styles.icon__no} />
              ) : null}
              <p>{advantage.content}</p>
            </div>
          );
        })}
      </div>

      {billing.name === "Pro" && (
        <div className={styles.button}>
          <CustomButton
            display
            content={billing.name === "Pro" ? "M'abonner" : "Essayer"}
            onclick={openStripeSubscriptionUrl}
            isLoading={isLoading}
            check={userStatut === billing.code}
            color={userStatut === billing.code ? (userStatut === "pro" ? "green" : "red") : null}
            disabled={userStatut === billing.code}
          />
        </div>
      )}
    </div>
  );
};

export default BillingCard;
