import { FaRegHandshake } from "react-icons/fa";
import HelpMenuItem from "../HelpMenuItem/HelpMenuItem";
import HelpGoBack from "../HelpGoBack/HelpGoBack";

const HelpTutoriel = ({ changeIndex }) => {
  return (
    <div>
      <HelpGoBack changeIndex={() => changeIndex(0)} />
      <h3>Choisissez un sujet sur lequel vous souhaiteriez en apprendre d'avantage.</h3>

      <HelpMenuItem Icon={<FaRegHandshake />} title={"La démarche de vente"} indexClick={() => changeIndex(2)} />
    </div>
  );
};

export default HelpTutoriel;
