import { useContext } from "react";
import { UserStatutContext } from "context/userStatutContext";

const useUserStatut = () => {
  const { userStatut, refetchUserStatut } = useContext(UserStatutContext);

  return { userStatut, refetchUserStatut };
};

export default useUserStatut;
