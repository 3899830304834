import { BiBook } from "react-icons/bi";
import { BiMailSend } from "react-icons/bi";

// Components
import HelpMenuItem from "../HelpMenuItem/HelpMenuItem";

const HelpMenu = ({ changeIndex }) => {
  return (
    <div>
      <h3>Comment pouvons-vous vous aider ?</h3>

      <HelpMenuItem Icon={<BiBook />} title={"Tutoriels"} indexClick={() => changeIndex(1)} />
      <HelpMenuItem Icon={<BiMailSend />} title={"Nous contacter"} indexClick={() => changeIndex(-1)} />
    </div>
  );
};

export default HelpMenu;
