import { useContext } from "react";
import { ErrorModaleContext } from "context/errorModale";

const useErrorModale = () => {
  const { errorModaleName, setErrorModaleName } = useContext(ErrorModaleContext);

  return { errorModaleName, setErrorModaleName };
};

export default useErrorModale;
