import { useContext } from "react";
import { AuthContext } from "context/authContext";

const useAccessToken = () => {
  const { userToken, setTokenChange } = useContext(AuthContext);

  return { userToken, setTokenChange };
};

export default useAccessToken;
