import { useState } from "react";
import GoBack from "components/BasicComponents/GoBack/GoBack";
import CustomInput from "components/BasicComponents/FormsComponents/CustomInput/CustomInput";
import CustomButton from "components/BasicComponents/FormsComponents/CustomButton/CustomButton";
import DisplayGptResponse from "components/GenerationComponents/DisplayGptResponse/DisplayGptResponse";
import GenerationFormTitle from "components/GenerationComponents/GenerationFormTitle/GenerationFormTitle";
import { FaRegHandshake } from "react-icons/fa";
import useSubmitGptForm from "hooks/useSubmitGptForm";

import SelectOrWriteProduct from "components/BasicComponents/FormsComponents/SelectOrWriteProduct/SelectOrWriteProduct";

const ResponseToObjection = () => {
  const [productToSale, setProductToSale] = useState("");
  const [customerCible, setCustomerCible] = useState("");
  const [objection, setObjection] = useState("");

  const [userProductSelected, setUserProductSelected] = useState(null);

  const { onSubmit, step, setStep, isLoading, isFinished, setIsFinished, response, errorMessage, userProductData } =
    useSubmitGptForm("/ai-generation/response-to-objection", [
      { productToSale: productToSale },
      { customerCible: customerCible },
      { objection: objection },
      { userProductSelected: userProductSelected },
    ]);

  return (
    <div className="container">
      <GoBack link={"/generer"} />

      <div className="formContainer">
        <GenerationFormTitle title={"Réponse à une objection"} Icon={<FaRegHandshake />} />

        {step === 0 && (
          <form onSubmit={onSubmit}>
            <SelectOrWriteProduct
              userProductData={userProductData}
              userProductSelected={userProductSelected}
              setUserProductSelected={setUserProductSelected}
              productToSale={productToSale}
              setProductToSale={setProductToSale}
            />

            <CustomInput
              label="Qui est la cible qui a émi cette objection ?"
              name="customerCible"
              value={customerCible}
              setValue={setCustomerCible}
            />

            <CustomInput
              label="Quelle objection souhaitez-vous traiter ?"
              name="objection"
              value={objection}
              setValue={setObjection}
            />

            <CustomButton
              content="Générer"
              display={(userProductSelected || productToSale) && customerCible && objection ? true : null}
            />

            {errorMessage && <p className="errorMessage">{errorMessage}</p>}
          </form>
        )}

        {step === 1 && (
          <DisplayGptResponse
            response={response}
            isLoading={isLoading}
            setStep={setStep}
            isFinished={isFinished}
            setIsFinished={setIsFinished}
            contentButton={"Générer un autre réponse à une objection"}
          />
        )}
      </div>
    </div>
  );
};

export default ResponseToObjection;
