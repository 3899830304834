// ** Hooks **
import useSendContactMail from "hooks/useSendContactMail";

// ** Components **
import HelpGoBack from "../HelpGoBack/HelpGoBack";
import CustomInput from "components/BasicComponents/FormsComponents/CustomInput/CustomInput";
import CustomButton from "components/BasicComponents/FormsComponents/CustomButton/CustomButton";
import DisplayServerResponse from "components/BasicComponents/DisplayServeurReponse/DisplayServerResponse";
import CustomLoader from "components/BasicComponents/CustomLoader/CustomLoader";

const HelpContact = ({ changeIndex }) => {
  const { userFirstName, setUserFirstName, userMessage, setUserMessage, responseMessage, sendContactMail, isLoading } =
    useSendContactMail();

  return (
    <div>
      <HelpGoBack changeIndex={() => changeIndex(0)} />
      <h3>Une question, un besoin ? Contactez-nous ! 😉</h3>

      <CustomInput label={"Prénom"} value={userFirstName} setValue={setUserFirstName} />
      <CustomInput label={"Message"} type={"area"} value={userMessage} setValue={setUserMessage} />

      {userFirstName && userMessage && !isLoading && (
        <CustomButton display content={"Envoyer"} style={{ width: "100%" }} onclick={sendContactMail} />
      )}

      {isLoading && <CustomLoader small />}

      {responseMessage && <DisplayServerResponse message={responseMessage} />}
    </div>
  );
};

export default HelpContact;
