import CustomButton from "../FormsComponents/CustomButton/CustomButton";
import styles from "./protectedLayer.module.scss";

import useUserDroits from "hooks/useUserDroits";

import { HiOutlineLockClosed } from "react-icons/hi";

import { useNavigate } from "react-router-dom";

const ProtectedLayer = ({ children }) => {
  const isAuthorized = useUserDroits();
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      {!isAuthorized && (
        <div className={styles.layer}>
          <div>
            <HiOutlineLockClosed />
            <p>Outch ! Vous avez atteint la limite d'utilisations en mode gratuit.</p>
            <p> N'hésitez pas à vous abonner pour continuer à en profiter :</p>
            <CustomButton display content="M'abonner" onclick={() => navigate("/reglages?focus=abonnement")} />
          </div>
        </div>
      )}
      <div className={!isAuthorized ? styles.protectedScroll : null}>{children}</div>
    </div>
  );
};

export default ProtectedLayer;
