import styles from "./displayServerResponse.module.scss";

const DisplayServerResponse = ({ message }) => {
  return (
    <p
      className={[styles.message, message?.type === "success" ? styles.successMessage : styles.errorMessage].join(" ")}
    >
      {message?.message}
    </p>
  );
};

export default DisplayServerResponse;
