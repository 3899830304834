import { useState } from "react";
import HistoryFolder from "components/HistoryComponents/HistoryFolder/HistoryFolder";
import styles from "./history.module.scss";
import { useQuery } from "react-query";
import { fetchHistory, deleteHistoryById } from "controllers/history.controllers";
import useAccessToken from "hooks/useAccessToken";
import CustomLoader from "components/BasicComponents/CustomLoader/CustomLoader";
import ErrorMessageDisplay from "components/ErrorMessageDisplay/ErrorMessageDisplay";
import useErrorModale from "hooks/useErrorModale";
import useUserProduct from "hooks/useUserProducts";

import HistoryVisualisation from "components/HistoryComponents/HistoryVisualisation/HistoryVisualisation";
import HistoryFile from "components/HistoryComponents/HistoryFile/HistoryFile";
import HistoryGridHeader from "components/HistoryComponents/HistoryGridHeader/HistoryGridHeader";
import { useLocation } from "react-router-dom";

const History = () => {
  const location = useLocation();
  const accessToken = useAccessToken();
  const { userProductData, userProductLoading } = useUserProduct();
  const { setErrorModaleName } = useErrorModale();
  const [isModaleVisualisationOpen, setIsModaleVisualisationOpen] = useState(location?.state?.activeLeftPanel || null);
  const [productSelected, setProductSelected] = useState("Tous produits");

  const { data, isLoading, error, refetch } = useQuery("files", () => fetchHistory(accessToken?.userToken), {
    enabled: !!accessToken,
    onError: (error) => {
      if (error?.response?.status === 401) {
        setErrorModaleName("session_expired");
      }
    },
  });

  const deleteHistory = async (id) => {
    await deleteHistoryById(accessToken.userToken, id, refetch, setErrorModaleName);
    setIsModaleVisualisationOpen(null);
  };

  return (
    <div className={styles.history}>
      <div className={isModaleVisualisationOpen ? styles.history__between : null}>
        <h1 className="inDivContainer">Historique</h1>
        <h6 className="inDivContainer">Retrouvez l'ensemble des textes de vente que vous avez générés.</h6>

        <div className={styles.folderWrapper}>
          {userProductData && (
            <>
              <HistoryFolder
                numberOfFiles={data?.length}
                productName={"Tout produits"}
                onClick={() => setProductSelected("Tous produits")}
                focus={productSelected === "Tous produits"}
              />
              {!userProductLoading && userProductData?.length > 0 && (
                <>
                  {userProductData?.map((product) => {
                    const productName = product?.name;
                    const numberOfFiles = data?.filter((file) => file?._userProductId?.name === productName).length;

                    return (
                      <HistoryFolder
                        key={productName}
                        numberOfFiles={numberOfFiles}
                        productName={productName}
                        onClick={() => setProductSelected(productName)}
                        focus={productSelected === productName}
                      />
                    );
                  })}
                  <HistoryFolder
                    numberOfFiles={data?.filter((file) => !file?._userProductId?.name)?.length}
                    productName={"Produits divers"}
                    onClick={() => setProductSelected("Produits divers")}
                    focus={productSelected === "Produits divers"}
                  />
                </>
              )}
            </>
          )}
        </div>

        <div className={styles.files__wrapper}>
          {isLoading && (
            <div className="dfCenterContainer">
              <CustomLoader />
            </div>
          )}

          {error && !data && <ErrorMessageDisplay />}

          {data && (
            <div className={[styles.files__container, "inDivContainer"].join(" ")}>
              <h3>{productSelected}</h3>

              <HistoryGridHeader />

              <div className={styles.files__display}>
                {data && data.length > 0 ? (
                  data
                    .filter((file) => {
                      if (productSelected === "Tous produits") {
                        return file;
                      } else if (productSelected === "Produits divers") {
                        return !file?._userProductId?.name;
                      } else {
                        return file?._userProductId?.name === productSelected;
                      }
                    })
                    .map((file) => {
                      return (
                        <HistoryFile
                          key={file?._id}
                          file={file}
                          deleteFunction={() => deleteHistory(file?._id)}
                          onClick={() => setIsModaleVisualisationOpen(file)}
                          isModaleVisualisationOpen={isModaleVisualisationOpen}
                        />
                      );
                    })
                ) : (
                  <p className={styles.emptyProduct}>Aucun historique à afficher</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {isModaleVisualisationOpen && (
        <HistoryVisualisation
          isModaleVisualisationOpen={isModaleVisualisationOpen}
          setIsModaleVisualisationOpen={setIsModaleVisualisationOpen}
          deleteFunction={deleteHistory}
        />
      )}
    </div>
  );
};

export default History;
