import styles from "./GenerationCard.module.scss";
import GenerationIconBox from "components/GenerationComponents/GenerationIconBox/GenerationIconBox";

import { Link } from "react-router-dom";

const GenerationCard = ({ item, Icon }) => {
  return (
    <Link className={[styles.container, item.link ? styles.pointer : null].join(" ")} to={item.link}>
      {!item.link && (
        <div className={styles.comingSoon}>
          <p>Coming soon</p>
        </div>
      )}

      <GenerationIconBox Icon={Icon} color={item.color} bgColor={item.bgColor} boxShadow={item.boxShadow} />

      <div>
        <h3>{item.name}</h3>

        <p>{item.description}</p>
      </div>
    </Link>
  );
};

export default GenerationCard;
