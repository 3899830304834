import styles from "./ConnexionPage.module.scss";

import screenshot from "assets/pictures/screenshot_salesCopilot.png";

import { ReactComponent as Icon } from "assets/pictures/sales_colpilot_logo.svg";

const ConnexionPage = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <a href="https://sales-copilot.com" target="_blank" rel="noreferrer">
          <div className={styles.boxLogo}>
            <Icon className={styles.logo} />
            <span className={styles.box__name}>SalesCopilot</span>
          </div>
        </a>

        {children}

        <h4>© Sales Copilot 2023</h4>
      </div>

      <div className={styles.illustrationContainer}>
        <img src={screenshot} alt={"Utilisateur de Sales Copilot"} />

        <h5>
          Vos <span className={styles.gradientText}>discours de vente</span> sur mesure et percutants{" "}
          <span className={styles.gradientText}>grâce à l&apos;IA</span>
        </h5>
        <h6>
          L'IA au service de votre chiffre d'affaires. Décuplez votre impact commercial avec des discours de vente
          percutants qui font la difference.
        </h6>
      </div>
    </div>
  );
};

export default ConnexionPage;
