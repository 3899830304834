import { BiArrowBack } from "react-icons/bi";
import styles from "./helpGoBack.module.scss";

const HelpGoBack = ({ changeIndex }) => {
  return (
    <div className={styles.container} onClick={changeIndex}>
      <BiArrowBack />
      <p>Retour</p>
    </div>
  );
};

export default HelpGoBack;
