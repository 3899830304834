import { useContext } from "react";
import { UserProductContext } from "context/userProduct";

const useUserProduct = () => {
  const { userProductData, userProductLoading, userProductError, userProductRefetch } = useContext(UserProductContext);

  return { userProductData, userProductLoading, userProductError, userProductRefetch };
};

export default useUserProduct;
