import { createContext } from "react";
import { useQuery } from "react-query";

import useAccessToken from "hooks/useAccessToken";
import useErrorModale from "hooks/useErrorModale";
import { getUserStatut } from "controllers/user.controllers";

export const UserStatutContext = createContext();

const UserStatutProvider = ({ children }) => {
  const { userToken } = useAccessToken();
  const { setErrorModaleName } = useErrorModale();

  const { data: userStatut, refetch: refetchUserStatut } = useQuery("userStatut", () => getUserStatut(userToken), {
    enabled: !!userToken,
    onError: (error) => {
      if (error?.response?.status === 401) {
        setErrorModaleName("session_expired");
      }
    },
  });

  return <UserStatutContext.Provider value={{ userStatut, refetchUserStatut }}>{children}</UserStatutContext.Provider>;
};

export default UserStatutProvider;
