import { Link } from "react-router-dom";
import styles from "./settingsMenu.module.scss";

const SettingsMenu = ({ focus, setIsOpenLeftPanel }) => {
  const allSections = [
    {
      desktop: "Descriptions produits",
      mobile: "Produits",
      focus: "descriptions-produits",
    },
    {
      desktop: "Mot de passe",
      mobile: "Mot de passe",
      focus: "mot-de-passe",
    },
    {
      desktop: "Mon compte",
      mobile: "Compte",
      focus: "compte",
    },
    {
      desktop: "Mon abonnement",
      mobile: "Abonnement",
      focus: "abonnement",
    },
  ];

  const changeSettingsSelection = () => {
    setIsOpenLeftPanel(null);
  };

  return (
    <div className={styles.container}>
      {allSections.map((section, index) => {
        return (
          <Link key={index} to={`/reglages?focus=${section.focus}`}>
            <div
              onClick={() => changeSettingsSelection()}
              className={
                (!focus && section.focus === "descriptions-produits") || section.focus === focus ? styles.focus : null
              }
            >
              <p>{section.desktop}</p>
              <p>{section.mobile}</p>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default SettingsMenu;
