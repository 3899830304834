import styles from "./billingMenu.module.scss";
import billingData from "data/billingData";
import BillingCard from "./BillingCard/BillingCard";
import useUserStatut from "hooks/useUserStatut";
import CustomButton from "components/BasicComponents/FormsComponents/CustomButton/CustomButton";
import useGetStripeUrl from "hooks/useGetStripeUrl";
import { useEffect } from "react";

const BillingMenu = () => {
  const { userStatut, refetchUserStatut } = useUserStatut();
  const { openStripeSpaceUrl, isLoading } = useGetStripeUrl();

  useEffect(() => {
    refetchUserStatut();
  }, [refetchUserStatut]);

  return (
    <div className={styles.wrapper}>
      <h3>Mon abonnement</h3>
      <h4>Changez facilement votre abonnement, pour un maximum de liberté.</h4>

      <div className={styles.billingWrapper}>
        {billingData.map((billing, index) => {
          return <BillingCard key={index} billing={billing} userStatut={userStatut} />;
        })}
      </div>

      <div className={styles.section}>
        <div className={styles.section}>
          <div className={styles.section__title}>
            <h5>Gérer mon abonnement, mes factures et mes moyens de paiement</h5>
            <p>Tout votre abonnement, au même endroit</p>
          </div>
          <div className={styles.section__right}>
            <CustomButton display content={"Gérer"} isLoading={isLoading} onclick={openStripeSpaceUrl} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingMenu;
