import styles from "./productLeftPanel.module.scss";

import { BiListCheck } from "react-icons/bi";

// ** Components **
import CustomInput from "components/BasicComponents/FormsComponents/CustomInput/CustomInput";
import CustomButton from "components/BasicComponents/FormsComponents/CustomButton/CustomButton";

// ** Hooks **
import useSubmitProductForm from "hooks/useSubmitProductForm";
import CustomLoader from "components/BasicComponents/CustomLoader/CustomLoader";

const ProductLeftPanel = ({ product, setIsOpenLeftPanel }) => {
  const { name, setName, description, setDescription, isLoading, onSubmit, errorMessage } = useSubmitProductForm(
    product,
    setIsOpenLeftPanel
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <BiListCheck />
        <h2>Description produit</h2>
      </div>

      <div className={styles.inputs}>
        <CustomInput label="Nom du produit" name="name" value={name} setValue={setName} />

        <CustomInput
          label={
            <p>
              Décrivez-nous <span>le plus précisement possible </span>ce que vous vendez.
            </p>
          }
          type={"area"}
          name="description"
          value={description}
          setValue={setDescription}
        />
      </div>

      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}

      {!isLoading ? (
        <CustomButton
          content={product?._id ? "Modifier" : "Créer"}
          display={name && description ? true : null}
          onclick={onSubmit}
        />
      ) : (
        <CustomLoader />
      )}
    </div>
  );
};

export default ProductLeftPanel;
