import { useState } from "react";
import { apiUrl } from "configs/apiUri";
import axios from "axios";
import useAccessToken from "hooks/useAccessToken";

const useSendContactMail = () => {
  const { userToken } = useAccessToken();
  const [userFirstName, setUserFirstName] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [responseMessage, setResponseMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const sendContactMail = async () => {
    try {
      if (!userFirstName || !userMessage)
        return setResponseMessage({ type: "error", message: "Veuillez remplir tous les champs." });

      if (userMessage.length < 5)
        return setResponseMessage({ type: "error", message: "Votre message doit contenir au moins 5 caractères." });

      setIsLoading(true);

      const response = await axios.post(
        `${apiUrl}/contact`,
        { userFirstName, userMessage },
        { headers: { Authorization: `Bearer ${userToken}` } }
      );

      setUserFirstName("");
      setUserMessage("");

      setIsLoading(false);

      setResponseMessage({
        type: "success",
        message: "Votre message a bien été envoyé, nous vous repondrons le plus rapidement possible !",
      });

      setTimeout(() => {
        setResponseMessage(null);
      }, 1800);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      setResponseMessage({
        type: "error",
        message: "Une erreur s'est produite lors de l'envoi du message, veuillez réessayer plus tard.",
      });
      console.error(error);
    }
  };

  return {
    userFirstName,
    setUserFirstName,
    userMessage,
    setUserMessage,
    responseMessage,
    setResponseMessage,
    sendContactMail,
    isLoading,
  };
};

export default useSendContactMail;
