import { useState } from "react";

// Custom Hooks
import useAccessToken from "hooks/useAccessToken";
import useErrorModale from "hooks/useErrorModale";
import useUserProduct from "./useUserProducts";

import { handleFormSubmit } from "controllers/handleSubmitForm";
import checkGptData from "utils/checkData/checkGptData/checkGptData";

import useNumberOfCreations from "./useNumberOfCreations";

const useSubmitGptForm = (route, datas) => {
  const { userProductData } = useUserProduct();
  const { refetchNumberOfCreations } = useNumberOfCreations();

  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [response, setResponse] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { userToken } = useAccessToken();
  const { setErrorModaleName } = useErrorModale();

  const onSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    const gptData = checkGptData(datas, setErrorMessage);

    if (!gptData) {
      return;
    }

    setStep(1);

    handleFormSubmit(
      route,
      gptData,
      setIsLoading,
      setResponse,
      setIsFinished,
      userToken,
      setErrorModaleName,
      refetchNumberOfCreations
    );
  };

  return { step, setStep, setIsFinished, isLoading, isFinished, response, onSubmit, errorMessage, userProductData };
};

export default useSubmitGptForm;
