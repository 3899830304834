import styles from "./headerMobile.module.scss";
import { menuItemList } from "data/menuItemList";

import { BiPlus } from "react-icons/bi";
import { BiHelpCircle } from "react-icons/bi";
import { Link } from "react-router-dom";

import CustomButton from "components/BasicComponents/FormsComponents/CustomButton/CustomButton";

import { useNavigate } from "react-router-dom";
import useUserStatut from "hooks/useUserStatut";

const HeaderMobile = ({ setIsOpenMobile, isOpenMobile, setIsHelpModale }) => {
  const { userStatut } = useUserStatut();
  const navigate = useNavigate();

  return (
    <div className={[styles.container, !isOpenMobile ? styles.container__close : null].join(" ")}>
      <div className={styles.topMenu}>
        <div className={styles.boxLogo}>
          <span className={styles.box__name}>SalesCopilot</span>
          <span>Beta</span>
        </div>

        <BiPlus
          className={[styles.chevron].join(" ")}
          onClick={() => {
            setIsOpenMobile(false);
          }}
        />
      </div>

      <nav>
        <ul className={[styles.menu].join(" ")}>
          {menuItemList.map((item, index) => {
            return (
              <Link
                to={item.link}
                key={index}
                onClick={() => {
                  setIsOpenMobile(false);
                }}
              >
                <li>{item.name}</li>
              </Link>
            );
          })}
        </ul>
      </nav>

      {userStatut === "basic" && (
        <CustomButton
          display
          content="M'abonner"
          onclick={() => {
            navigate("/reglages?focus=abonnement");
            setIsOpenMobile(false);
          }}
        />
      )}

      <div
        className={[styles.lastMenu, styles.box].join(" ")}
        onClick={() => {
          setIsHelpModale(true);
          setIsOpenMobile(false);
        }}
      >
        <BiHelpCircle />
      </div>
    </div>
  );
};

export default HeaderMobile;
