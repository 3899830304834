import React, { useState } from "react";
import styles from "./page.module.scss";
import Header from "components/Header/Header";
import HeaderMobile from "components/Header/HeaderMobile/HeaderMobile";
import ErrorModales from "components/Modales/ErrorModales/ErrorModales";
import HelpModale from "components/HelpComponents/HelpModale/HelpModale";

import { BiMenu } from "react-icons/bi";

const Page = ({ children }) => {
  const [isOpenMobile, setIsOpenMobile] = useState(false);
  const [isHelpModale, setIsHelpModale] = useState(false);

  return (
    <div className={styles.wrapper}>
      <Header setIsHelpModale={setIsHelpModale} />
      <HeaderMobile isOpenMobile={isOpenMobile} setIsOpenMobile={setIsOpenMobile} setIsHelpModale={setIsHelpModale} />
      <div className={styles.container}>
        <div>
          <BiMenu
            onClick={() => {
              setIsOpenMobile(true);
            }}
          />
          {children}
        </div>
      </div>

      <ErrorModales />

      {isHelpModale && <HelpModale setIsHelpModale={setIsHelpModale} />}
    </div>
  );
};

export default Page;
