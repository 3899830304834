import { Link, useNavigate } from "react-router-dom";
import { useState, useContext } from "react";

import { AuthContext } from "context/authContext";

import ConnexionPage from "components/ConnexionPage/ConnexionPage";
import CustomInput from "components/BasicComponents/FormsComponents/CustomInput/CustomInput";
import CustomButton from "components/BasicComponents/FormsComponents/CustomButton/CustomButton";
import CustomLoader from "components/BasicComponents/CustomLoader/CustomLoader";

import { handleSubmitLogin } from "controllers/user.controllers";

const Login = () => {
  const navigate = useNavigate();
  const { setTokenChange } = useContext(AuthContext);
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <ConnexionPage>
      <form
        onSubmit={(e) => {
          handleSubmitLogin(e, userEmail, userPassword, setErrorMessage, setIsLoading, setTokenChange, navigate);
        }}
      >
        <h1>Connexion</h1>
        <h2>Bon retour sur Sales Copilot, l'application qui booste vos discours de vente.</h2>

        <CustomInput label="Email" type="email" paddingBottom="20px" value={userEmail} setValue={setUserEmail} />

        <CustomInput
          label="Mot de passe"
          type={"password"}
          paddingBottom="4px"
          value={userPassword}
          setValue={setUserPassword}
        />

        {!isLoading ? (
          <CustomButton
            content={"Connexion"}
            display
            style={{ width: "100%", background: "#1b1c1f", boxShadow: "none" }}
          />
        ) : (
          <CustomLoader style={{ color: "#1b1c1f", height: "30px", width: "30px" }} />
        )}

        {errorMessage && <p style={{ color: "red", fontStyle: "italic", marginBottom: "20px" }}>{errorMessage}</p>}

        <p>
          Pas encore inscrit ? <Link to={"/inscription"}>C'est par ici.</Link>
        </p>
      </form>
    </ConnexionPage>
  );
};

export default Login;
