import styles from "./generate.module.scss";

import { useState } from "react";

import { modelGenerationItem } from "data/modelGenerationItem";

import { BiTargetLock } from "react-icons/bi";
import { BiUser } from "react-icons/bi";
import { BiMailSend } from "react-icons/bi";
import { BiPhone } from "react-icons/bi";
import { FaRegHandshake } from "react-icons/fa";
import { MdWavingHand } from "react-icons/md";
import { BiQuestionMark } from "react-icons/bi";
import { BiCommentCheck } from "react-icons/bi";
import { BsCardChecklist } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { GoChecklist } from "react-icons/go";

import GenerateCardWrapper from "components/GenerationComponents/GenerateCardWrapper/GenerateCardWrapper";

const Generate = () => {
  const [selected, setSelected] = useState("tous");

  const categories = ["tous", "cibler", "prospecter", "vendre"];

  const Icon = [
    { icon: BiTargetLock, name: "Ciblage clients", categorie: "cibler" },
    { icon: BiUser, name: "Définir un persona", categorie: "cibler" },
    { icon: BiMailSend, name: "Email de prospection", categorie: "prospecter" },
    { icon: FaLinkedinIn, name: "Message privé Linkedin", categorie: "prospecter" },
    { icon: BiPhone, name: "Pitch téléphonique", categorie: "prospecter" },
    { icon: MdWavingHand, name: "Prise de contact", categorie: "vendre" },
    { icon: BiQuestionMark, name: "Recueil des besoins", categorie: "vendre" },
    { icon: BiCommentCheck, name: "Arguments à la volée", categorie: "vendre" },
    { icon: BsCardChecklist, name: "Argumentaire", categorie: "vendre" },
    { icon: FaRegHandshake, name: "Réponse à une objection", categorie: "vendre" },
    { icon: GoChecklist, name: "Tableau d'objections", categorie: "vendre" },
  ];

  return (
    <div className="container">
      <h1>Générer</h1>
      <h6>Génerez des discours de ventes adaptés à vos besoins.</h6>

      <div className={styles.selectionsWrapper}>
        {categories.map((title, index) => {
          return (
            <p
              key={index}
              className={[styles.selection, selected === title ? styles.userSelect : null].join(" ")}
              onClick={() => setSelected(title)}
            >
              {title.charAt(0).toUpperCase() + title.substring(1)}
            </p>
          );
        })}
      </div>

      {selected === "tous" ? (
        categories.map((categorie, index) => {
          return (
            <GenerateCardWrapper
              key={index}
              data={modelGenerationItem.filter((item) => item.categorie === categorie)}
              Icon={Icon.filter((iconLine) => iconLine.categorie === categorie)}
              title={categorie}
            />
          );
        })
      ) : (
        <GenerateCardWrapper
          data={modelGenerationItem.filter((item) => item.categorie === selected)}
          Icon={Icon.filter((iconLine) => iconLine.categorie === selected)}
          title={selected}
        />
      )}
    </div>
  );
};

export default Generate;
