import { BiHomeAlt } from "react-icons/bi";
import { BiBot } from "react-icons/bi";
import { BiFolderOpen } from "react-icons/bi";
import { BiWrench } from "react-icons/bi";

import styles from "./menuItem.module.scss";

import { useLocation, Link } from "react-router-dom";

const MenuItem = ({ item, index }) => {
  const { pathname } = useLocation();

  const icons = [BiHomeAlt, BiBot, BiFolderOpen, BiWrench];

  const ActualIcon = icons[index];

  return (
    <Link to={item.link}>
      <li className={"/" + pathname?.split("/")[1] === item.link ? styles.focus : null}>
        <ActualIcon />
        <span>{item.name}</span>
      </li>
    </Link>
  );
};

export default MenuItem;
