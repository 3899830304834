import styles from "./quickAccess.module.scss";

import { modelGenerationItem } from "data/modelGenerationItem";
import { BiTargetLock } from "react-icons/bi";
import { BiUser } from "react-icons/bi";
import { BiMailSend } from "react-icons/bi";
import { BiPhone } from "react-icons/bi";
import { FaRegHandshake } from "react-icons/fa";
import { MdWavingHand } from "react-icons/md";
import { BiQuestionMark } from "react-icons/bi";
import { BiCommentCheck } from "react-icons/bi";
import { BsCardChecklist } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { GoChecklist } from "react-icons/go";
import { Link } from "react-router-dom";

const QuickAccess = () => {
  const Icon = [
    BiTargetLock,
    BiUser,
    BiMailSend,
    FaLinkedinIn,
    BiPhone,
    MdWavingHand,
    BiQuestionMark,
    BsCardChecklist,
    BiCommentCheck,
    GoChecklist,
    FaRegHandshake,
  ];

  return (
    <div className={styles.quickAccess}>
      {modelGenerationItem.map((item, index) => {
        const IconComponent = Icon[index];
        return (
          <Link to={"generer/" + item.link} key={index}>
            <IconComponent style={{ color: item.color }} />

            <p>{item.name}</p>
          </Link>
        );
      })}
    </div>
  );
};

export default QuickAccess;
