import useUserStatut from "./useUserStatut";
import useNumberOfCreations from "./useNumberOfCreations";

const useUserDroits = () => {
  const { numberOfCreation } = useNumberOfCreations();
  const { userStatut } = useUserStatut();

  if (userStatut === "pro") return true;

  if (userStatut === "basic" && numberOfCreation >= 15) return false;

  if (userStatut === "basic" && numberOfCreation < 15) return true;
};

export default useUserDroits;
