import CustomButton from "components/BasicComponents/FormsComponents/CustomButton/CustomButton";
import useErrorModale from "hooks/useErrorModale";
import styles from "./errorConnexionModale.module.scss";
import ModaleTitle from "components/Modales/ModaleTitle/ModaleTitle";

import useDeconnexion from "hooks/useDeconnexion";

const ErrorConnexionModale = () => {
  const { deconnexion } = useDeconnexion();
  const { setErrorModaleName } = useErrorModale();

  const closeModale = () => {
    deconnexion();
    setErrorModaleName("");
  };

  return (
    <div className={styles.container}>
      <ModaleTitle onClick={closeModale} title={<h2>Session expirée</h2>} />
      <p>Veuillez vous reconnecter pour accéder de nouveau au service.</p>

      <CustomButton display content={"Se connecter"} onclick={closeModale} />
    </div>
  );
};

export default ErrorConnexionModale;
