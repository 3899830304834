import { useState } from "react";

import styles from "./accountManagement.module.scss";

// ** Components **
import CustomButton from "components/BasicComponents/FormsComponents/CustomButton/CustomButton";
import CustomInput from "components/BasicComponents/FormsComponents/CustomInput/CustomInput";

// ** Hooks **
import { useQuery } from "react-query";
import useAccessToken from "hooks/useAccessToken";
import useDeconnexion from "hooks/useDeconnexion";

// ** Controllers **
import { getMail, updateEmail } from "controllers/user.controllers";
import CustomLoader from "components/BasicComponents/CustomLoader/CustomLoader";

const AccountManagement = () => {
  const { deconnexion } = useDeconnexion();
  const { userToken } = useAccessToken();
  const [userEmail, setUserEmail] = useState("");
  const [message, setMessage] = useState(null);

  const { isLoading } = useQuery("mail", () => getMail(userToken), {
    onSuccess: (data) => {
      setUserEmail(data.email);
    },
  });

  return (
    <div className={styles.wrapper}>
      <h3>Mon compte</h3>
      <h4>Mettez ici à jours facilement votre profil.</h4>

      <div className={styles.section}>
        <div className={styles.section__title}>
          <h5>Informations personnelles</h5>
          <p>Modifier mon adresse mail</p>
        </div>
        <div className={styles.section__right}>
          {isLoading ? (
            <CustomLoader />
          ) : (
            <>
              <CustomInput
                label={"Modifiez votre email"}
                value={userEmail}
                setValue={setUserEmail}
                paddingBottom={"15px"}
              />
              <CustomButton
                display
                content={"Mettre à jour"}
                style={{ width: "100%" }}
                onclick={() => {
                  updateEmail(userToken, userEmail, setMessage);
                }}
              />
              {message && (
                <p className={message?.type === "success" ? styles.successMessage : styles.errorMessage}>
                  {message?.message}
                </p>
              )}
            </>
          )}
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.section__title}>
          <h5>Se déconnecter</h5>
        </div>
        <div className={styles.section__right}>
          <CustomButton
            display
            content={"Déconnexion"}
            cssClass={styles.deconnexion}
            onclick={deconnexion}
            color="red"
          />
        </div>
      </div>
    </div>
  );
};

export default AccountManagement;
