import { apiUrl } from "configs/apiUri";

export const handleFormSubmit = (
  route,
  bodyData,
  setIsLoading,
  setResponse,
  setFinished,
  accessToken,
  setErrorModaleName,
  refetchNumberOfCreations
) => {
  setIsLoading(true);

  fetch(`${apiUrl}${route}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(bodyData),
  })
    .then(async (response) => {
      if (response.status === 401) {
        setErrorModaleName("session_expired");
        return;
      }

      const reader = response.body.getReader();
      let result = "";
      setIsLoading(false);

      reader.read().then(async function processText({ done, value }) {
        if (done) {
          return;
        }

        result += new TextDecoder("utf-8").decode(value);

        const linesBefore = result.split("\n").filter((line) => line.trim() !== "");

        let result2 = "";
        for (const line of linesBefore) {
          const message = line.replace(/^data: /, "");
          if (message === "[DONE]") {
            setFinished(true);
            refetchNumberOfCreations();
            break;
          }

          try {
            const parsed = JSON.parse(message);
            const response = parsed.choices[0].delta.content;

            result2 += response;
            setResponse(result2);
          } catch (error) {
            console.error("Could not JSON parse stream message", message, error);
          }
        }

        return reader.read().then(processText);
      });
    })
    .catch((error) => {
      if (error.statut === 401) {
        console.log("401 error");
      }
    });
};
