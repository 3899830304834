import { useRoutes } from "react-router-dom";

// ** Pages **
import Signup from "layouts/Connexion/Signup/Signup";
import Login from "layouts/Connexion/Login/Login";
import Redirection from "layouts/Connexion/Redirection/Redirection";

const NoAuthRoutes = () => {
  const routes = useRoutes([
    {
      path: "/inscription",
      element: <Signup />,
    },
    {
      path: "/connexion",
      element: <Login />,
    },
    {
      path: "*",
      element: <Redirection />,
    },
  ]);

  return routes;
};

export default NoAuthRoutes;
