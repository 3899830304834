import styles from "./HelpMenuItem.module.scss";

import { BiChevronRight } from "react-icons/bi";

const HelpMenuItem = ({ Icon, title, indexClick }) => {
  return (
    <div className={styles.container} onClick={indexClick}>
      <div className={styles.container__title}>
        {Icon}
        <p>{title}</p>
      </div>
      <BiChevronRight />
    </div>
  );
};

export default HelpMenuItem;
