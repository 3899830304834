import styles from "./historyFile.module.scss";

import { formatDate } from "utils/formatDate";
import formatHistoryFileContent from "utils/formatHistoryFileContent";

import { TbFileFilled } from "react-icons/tb";
import { TbDotsVertical } from "react-icons/tb";
import { BiTrash } from "react-icons/bi";
import { useEffect, useState, useRef } from "react";

const HistoryFile = ({ file, deleteFunction, onClick, isModaleVisualisationOpen, location = "history" }) => {
  const content = useRef(null);
  const [divWidth, setDivWidth] = useState(null);

  const updateWindowDimensions = () => {
    const newDivWidth = content?.current?.clientWidth;
    setDivWidth(newDivWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    updateWindowDimensions();
  }, [isModaleVisualisationOpen]);

  return (
    <div key={file?._id} className={styles.file}>
      <div onClick={onClick}>
        <div className={styles.file__category}>
          <TbFileFilled style={{ color: file?._categoryId?.color }} />
          <p>{file?._categoryId?.name}</p>
        </div>

        <p ref={content}>{formatHistoryFileContent(file?.content, divWidth)}</p>

        <p>{formatDate(file?.createdAt)}</p>
      </div>

      {location === "history" && (
        <div className={styles.file__modify}>
          <TbDotsVertical />
          <div className={styles.menu}>
            <div className={styles.menu__option} onClick={deleteFunction}>
              <BiTrash />
              <p>Supprimer</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HistoryFile;
