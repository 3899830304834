import { BiX } from "react-icons/bi";
import styles from "./ModaleTitle.module.scss";

const ModaleTitle = ({ title, onClick }) => {
  return (
    <div className={styles.container}>
      {title}

      <div onClick={onClick}>
        <BiX />
      </div>
    </div>
  );
};

export default ModaleTitle;
