import styles from "./CustomCheckbox.module.scss";

const CustomCheckbox = ({ value, setValue, label }) => {
  return (
    <div className={styles.CustomCheckBox}>
      <input type="checkbox" id="checkbox" onChange={() => setValue(!value)} />
      {label}
    </div>
  );
};

export default CustomCheckbox;
