import CustomButton from "components/BasicComponents/FormsComponents/CustomButton/CustomButton";
import styles from "./paymentSuccess.module.scss";

import { BsHeartFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const PaymentSuccess = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h1>Paiement réussi !</h1>
        <div>
          <p>Merci à vous pour votre soutien au projet Sales Copilot.</p>
          <BsHeartFill />
        </div>

        <Link to="/">
          <CustomButton display content={"Accueil"} />
        </Link>
      </div>
    </div>
  );
};

export default PaymentSuccess;
