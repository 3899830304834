import { useContext } from "react";
import { NumberOfCreationContext } from "context/numberOfCreationContext";

const useNumberOfCreations = () => {
  const { numberOfCreation, refetchNumberOfCreations } = useContext(NumberOfCreationContext);

  return { numberOfCreation, refetchNumberOfCreations };
};

export default useNumberOfCreations;
