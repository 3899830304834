import styles from "./helpModale.module.scss";
import { useState } from "react";

// ** Components **
import ModaleWrapper from "components/Modales/ModaleWrapper/ModaleWrapper";
import ModaleTitle from "components/Modales/ModaleTitle/ModaleTitle";
import HelpMenu from "../HelpMenu/HelpMenu";
import HelpContact from "../HelpContact/HelpContact";
import HelpTutoriel from "../HelpTutoriel/HelpTutoriel";
import HelpSalesApproach from "../HelpSalesApproach/HelpSalesApproach";

const HelpModale = ({ setIsHelpModale }) => {
  const [indexNavigation, setIndexNavigation] = useState(0);

  const changeIndex = (index) => {
    setIndexNavigation(index);
  };

  return (
    <ModaleWrapper>
      <div className={styles.container}>
        <ModaleTitle title={<h2>Aide</h2>} onClick={() => setIsHelpModale(false)} />

        {indexNavigation === 0 && <HelpMenu changeIndex={changeIndex} />}
        {indexNavigation === -1 && <HelpContact changeIndex={changeIndex} />}
        {indexNavigation === 1 && <HelpTutoriel changeIndex={changeIndex} />}
        {indexNavigation === 2 && <HelpSalesApproach changeIndex={changeIndex} />}
      </div>
    </ModaleWrapper>
  );
};

export default HelpModale;
