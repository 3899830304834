import styles from "./selectOrWriteProduct.module.scss";

import { Link } from "react-router-dom";
import { BiPlus } from "react-icons/bi";

// ** Components **
import CustomInput from "components/BasicComponents/FormsComponents/CustomInput/CustomInput";
import CustomDropDown from "components/BasicComponents/FormsComponents/CustomDropDown/CustomDropDown";
import Or from "components/BasicComponents/FormsComponents/SelectOrWriteProduct/Or/Or";

const SelectOrWriteProduct = ({
  userProductData,
  userProductSelected,
  setUserProductSelected,
  productToSale,
  setProductToSale,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.firstSection}>
        <div className={styles.section}>
          <p>1</p>
          <h3>Produit</h3>
        </div>

        <Link to={"/reglages"}>
          <BiPlus />
          <p>Enregistrer un produit</p>
        </Link>
      </div>

      <CustomInput
        label={
          <p>
            Décrivez-nous <span>le plus précisement possible </span>ce que vous vendez.
          </p>
        }
        type={"area"}
        name="productToSale"
        value={productToSale}
        setValue={setProductToSale}
        paddingBottom={"0px"}
      />

      <Or />

      {userProductData?.length > 0 ? (
        <CustomDropDown
          placeholder={"Selectionnez votre produit"}
          data={userProductData}
          value={userProductSelected}
          setValue={setUserProductSelected}
        />
      ) : (
        <div className={styles.emptyProduct}>
          <Link to={"/reglages"}>
            <p>Gagnez du temps et enregistrez les déscriptions de vos produits.</p>
            <BiPlus />
          </Link>
        </div>
      )}

      {userProductData?.length > 0 && (
        <p>Si vous sélectionnez un produit, cela annule le texte tapé dans le champ du dessus.</p>
      )}

      <div className={styles.separator}></div>

      <div className={styles.section}>
        <p>2</p>
        <h3>Contexte</h3>
      </div>
    </div>
  );
};

export default SelectOrWriteProduct;
