const formatHistoryFileContent = (content, divWidth) => {
  let newContent = content?.replaceAll("#", "");
  newContent = newContent?.replaceAll("|", "");
  newContent = newContent?.replaceAll("-", "");
  newContent = newContent?.replaceAll("*", "");
  let contentString = "";
  if (!divWidth) {
    return (contentString = newContent.length > 60 ? `${newContent.slice(0, 100)}...` : newContent);
  }

  const numberOfLetter = divWidth / 7;

  contentString = newContent.length > 60 ? `${newContent.slice(0, numberOfLetter)}...` : newContent;

  return contentString;
};

export default formatHistoryFileContent;
