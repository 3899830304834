import useAccessToken from "hooks/useAccessToken";

import NoAuthRoutes from "routes/noAuthRoutes";
import AuthRoutes from "routes/authRoutes";

import Page from "components/BasicComponents/Page/Page";

import UserProductProvider from "context/userProduct";

const Routing = () => {
  const { userToken } = useAccessToken();

  return (
    <>
      {!userToken && <NoAuthRoutes />}

      {userToken && (
        <UserProductProvider>
          <Page>
            <AuthRoutes />
          </Page>
        </UserProductProvider>
      )}
    </>
  );
};

export default Routing;
