import { useState } from "react";
import GoBack from "components/BasicComponents/GoBack/GoBack";
import CustomInput from "components/BasicComponents/FormsComponents/CustomInput/CustomInput";
import CustomOnClickSelect from "components/BasicComponents/FormsComponents/CustomOnClickSelect/CustomOnClickSelect";
import CustomButton from "components/BasicComponents/FormsComponents/CustomButton/CustomButton";
import { userSituationList } from "data/formData/phonePitch.data";
import DisplayGptResponse from "components/GenerationComponents/DisplayGptResponse/DisplayGptResponse";
import GenerationFormTitle from "components/GenerationComponents/GenerationFormTitle/GenerationFormTitle";
import { MdWavingHand } from "react-icons/md";

import useSubmitGptForm from "hooks/useSubmitGptForm";

import SelectOrWriteProduct from "components/BasicComponents/FormsComponents/SelectOrWriteProduct/SelectOrWriteProduct";

const Contact = () => {
  const [userSituation, setUserSituation] = useState(null);
  const [productToSale, setProductToSale] = useState("");
  const [customerCible, setCustomerCible] = useState("");

  const [userProductSelected, setUserProductSelected] = useState(null);

  const { onSubmit, step, setStep, isLoading, isFinished, setIsFinished, response, errorMessage, userProductData } =
    useSubmitGptForm("/ai-generation/contact", [
      { productToSale: productToSale },
      { userSituation: userSituation },
      { customerCible: customerCible },
      { userProductSelected: userProductSelected },
    ]);

  return (
    <div className="container">
      <GoBack link={"/generer"} />

      <div className="formContainer">
        <GenerationFormTitle title={"Prise de contact"} Icon={<MdWavingHand />} />

        {step === 0 && (
          <form onSubmit={onSubmit}>
            <SelectOrWriteProduct
              userProductData={userProductData}
              userProductSelected={userProductSelected}
              setUserProductSelected={setUserProductSelected}
              productToSale={productToSale}
              setProductToSale={setProductToSale}
            />

            <CustomOnClickSelect
              list={userSituationList}
              label="Quel est votre fonction ?"
              value={userSituation}
              setValue={setUserSituation}
            />

            <CustomInput
              label="Avec qui avez-vous rendez-vous ?"
              name="customerCible"
              value={customerCible}
              setValue={setCustomerCible}
            />

            <CustomButton
              content="Générer"
              display={(productToSale || userProductSelected) && customerCible && userSituation ? true : null}
            />

            {errorMessage && <p className="errorMessage">{errorMessage}</p>}
          </form>
        )}

        {step === 1 && (
          <DisplayGptResponse
            response={response}
            isLoading={isLoading}
            setStep={setStep}
            isFinished={isFinished}
            setIsFinished={setIsFinished}
            contentButton={"Générer une autre prise de contact"}
          />
        )}
      </div>
    </div>
  );
};

export default Contact;
