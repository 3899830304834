import checkEmail from "../checkMailFormat/checkMailFormat.js";

export const checkSignup = (
  nameUser,
  emailUser,
  passwordUser,
  passwordConfirmUser,
  generalCondition,
  setErrorMessage
) => {
  if (!nameUser || !emailUser || !passwordUser || !passwordConfirmUser) {
    setErrorMessage("Veuillez remplir tous les champs pour vous inscrire.");
    return false;
  }

  if (!generalCondition) {
    setErrorMessage("Veuillez accepter les conditions générales d'utilisation pour vous inscrire.");
    return false;
  }

  if (!checkEmail(emailUser)) {
    setErrorMessage("Veuillez entrer une adresse email valide.");
    return false;
  }

  if (passwordUser.length < 8) {
    setErrorMessage("Le mot de passe doit contenir au moins 8 caractères.");
    return false;
  }

  if (passwordUser !== passwordConfirmUser) {
    setErrorMessage("Les mots de passe ne correspondent pas.");
    return false;
  }

  return true;
};
