import styles from "./generationIconBox.module.scss";

const GenerationIconBox = ({ Icon, color }) => {
  return (
    <div className={styles.emoji} style={{ color: color }}>
      {Icon && Icon}
    </div>
  );
};

export default GenerationIconBox;
