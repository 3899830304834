import { useState } from "react";
import GoBack from "components/BasicComponents/GoBack/GoBack";
import CustomInput from "components/BasicComponents/FormsComponents/CustomInput/CustomInput";
import CustomButton from "components/BasicComponents/FormsComponents/CustomButton/CustomButton";
import CustomOnClickSelect from "components/BasicComponents/FormsComponents/CustomOnClickSelect/CustomOnClickSelect";
import { userSituationList } from "data/formData/phonePitch.data";
import DisplayGptResponse from "components/GenerationComponents/DisplayGptResponse/DisplayGptResponse";
import GenerationFormTitle from "components/GenerationComponents/GenerationFormTitle/GenerationFormTitle";
import { FaLinkedinIn } from "react-icons/fa";
import useSubmitGptForm from "hooks/useSubmitGptForm";

import SelectOrWriteProduct from "components/BasicComponents/FormsComponents/SelectOrWriteProduct/SelectOrWriteProduct";

const LinkedinPrivateMessage = () => {
  const [userSituation, setUserSituation] = useState(null);
  const [productToSale, setProductToSale] = useState("");
  const [linkedinLink, setLinkedinLink] = useState("");

  const [userProductSelected, setUserProductSelected] = useState(null);

  const { onSubmit, step, setStep, isLoading, isFinished, setIsFinished, response, errorMessage, userProductData } =
    useSubmitGptForm("/ai-generation/linkedin-private-message", [
      { productToSale: productToSale },
      { userSituation: userSituation },
      { linkedinLink: linkedinLink },
      { userProductSelected: userProductSelected },
    ]);

  return (
    <div className="container">
      <GoBack link={"/generer"} />

      <div className="formContainer">
        <GenerationFormTitle title={"Message privé Linkedin"} Icon={<FaLinkedinIn />} />

        {step === 0 && (
          <form onSubmit={onSubmit}>
            <SelectOrWriteProduct
              userProductData={userProductData}
              userProductSelected={userProductSelected}
              setUserProductSelected={setUserProductSelected}
              productToSale={productToSale}
              setProductToSale={setProductToSale}
            />

            <CustomOnClickSelect
              list={userSituationList}
              label="Quel est votre fonction ?"
              value={userSituation}
              setValue={setUserSituation}
            />

            <CustomInput
              label={<p>Quelle est la fonction actuelle de votre prospect ?</p>}
              name="linkedinLink"
              value={linkedinLink}
              type={"area"}
              setValue={setLinkedinLink}
            />

            <CustomButton
              content="Générer"
              display={(userProductSelected || productToSale) && userSituation && linkedinLink ? true : null}
            />

            {errorMessage && <p className="errorMessage">{errorMessage}</p>}
          </form>
        )}

        {step === 1 && (
          <DisplayGptResponse
            response={response}
            isLoading={isLoading}
            setStep={setStep}
            isFinished={isFinished}
            setIsFinished={setIsFinished}
            contentButton={"Générer un autre message de contact Linkedin"}
          />
        )}
      </div>
    </div>
  );
};

export default LinkedinPrivateMessage;
