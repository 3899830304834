const formatDate = (date) => {
  let newDate = new Date(date);
  let dateInGoodFormat = newDate.toLocaleDateString("fr");

  return dateInGoodFormat;
};

const formatDateFr = (date) => {
  const month_names = [
    "Janvier",
    "Fevrier",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  let newDate = new Date(date);
  let dateInGoodFormat = newDate.toLocaleDateString("fr");
  dateInGoodFormat = dateInGoodFormat.split("/");

  const month = Number(dateInGoodFormat[1]);
  const monthInLetter = month_names[month - 1];

  const dateToReturn = [dateInGoodFormat[0], monthInLetter, dateInGoodFormat[2]];

  return dateToReturn;
};

export { formatDate, formatDateFr };
