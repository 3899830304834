import styles from "./displayGptResponse.module.scss";
import Lottie from "lottie-react";
import lottieBot from "assets/lotties/bot3.json";
import CustomLoader from "components/BasicComponents/CustomLoader/CustomLoader";
import CustomButton from "components/BasicComponents/FormsComponents/CustomButton/CustomButton";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const DisplayGptResponse = ({ isLoading, response, isFinished, setIsFinished, setStep, contentButton }) => {
  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className={styles.container}>
          <div className={styles.lottie__wrapper}>
            <div className={styles.lottie}>
              <Lottie animationData={lottieBot} />
            </div>
          </div>

          {isLoading ? (
            <CustomLoader />
          ) : (
            <div className={styles.markdownContainer}>
              <ReactMarkdown
                children={response?.slice(0, response.length - 9)?.slice(9, response.length)}
                className="reactMarkDown"
                remarkPlugins={[remarkGfm]}
              />
            </div>
          )}
        </div>
      )}

      {isFinished && (
        <CustomButton
          content={contentButton}
          onclick={() => {
            setStep(0);
            setIsFinished(false);
          }}
          display={isFinished ? true : null}
        />
      )}
    </div>
  );
};

export default DisplayGptResponse;
