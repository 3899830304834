import { createContext } from "react";
import { useQuery } from "react-query";

import useAccessToken from "hooks/useAccessToken";
import useErrorModale from "hooks/useErrorModale";
import { getNumberOfCreation } from "controllers/user.controllers";

export const NumberOfCreationContext = createContext();

const NumberOfCreationProvider = ({ children }) => {
  const { userToken } = useAccessToken();
  const { setErrorModaleName } = useErrorModale();

  const { data: numberOfCreation, refetch: refetchNumberOfCreations } = useQuery(
    "numberOfCreation",
    () => getNumberOfCreation(userToken),
    {
      enabled: !!userToken,
      onError: (error) => {
        if (error?.response?.status === 401) {
          setErrorModaleName("session_expired");
        }
      },
    }
  );

  return (
    <NumberOfCreationContext.Provider value={{ numberOfCreation, refetchNumberOfCreations }}>
      {children}
    </NumberOfCreationContext.Provider>
  );
};

export default NumberOfCreationProvider;
