import styles from "./customOnClickSelect.module.scss";

const CustomOnClickSelect = ({ list, label, value, setValue }) => {
  return (
    <div className={styles.container}>
      <p>{label}</p>
      <div className={styles.selectContainer}>
        {list.map((item, index) => {
          return (
            <div
              key={index}
              className={[styles.selectItem, value === item ? styles.selectItem__selected : null].join(" ")}
              onClick={() => {
                if (value === item) return setValue(null);

                setValue(item);
              }}
            >
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CustomOnClickSelect;
