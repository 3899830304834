import { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [userToken, setUserToken] = useState(false);
  const [tokenChange, setTokenChange] = useState(false);

  useEffect(() => {
    const getAccessToken = () => {
      const accessToken = Cookies.get("_access");
      setUserToken(accessToken);
    };

    getAccessToken();
  }, [tokenChange]);

  return <AuthContext.Provider value={{ userToken, setTokenChange }}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
