import styles from "./leftPanelWrapper.module.scss";

import { BiX } from "react-icons/bi";

const LeftPanelWrapper = ({ children, closePanel }) => {
  return (
    <div className={styles.container}>
      <div className={styles.toClose} onClick={closePanel}>
        <BiX />
      </div>

      {children}
    </div>
  );
};

export default LeftPanelWrapper;
