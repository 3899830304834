import { useState, useRef } from "react";
import styles from "./customInput.module.scss";

const CustomInput = ({ type, label, name, value, setValue, paddingBottom = "30px" }) => {
  const [isFocus, setIsFocus] = useState(false);
  const inputRef = useRef(null);

  return (
    <div
      className={[
        styles.container,
        isFocus ? styles.container__focus : null,
        !isFocus && value ? styles.container__complet : null,
      ].join(" ")}
      onBlur={() => setIsFocus(false)}
      style={{ paddingBottom: paddingBottom }}
    >
      <label
        htmlFor={name}
        className={[
          styles.label,
          isFocus ? styles.label__focus : null,
          !isFocus && value ? styles.label__complet : null,
        ].join(" ")}
        onClick={() => {
          setIsFocus(true);
          inputRef.current.focus();
        }}
      >
        {label}
      </label>
      {type === "area" ? (
        <textarea
          id={name}
          row={4}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          onClick={() => setIsFocus(true)}
          onBlurCapture={() => setIsFocus(false)}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          ref={inputRef}
        />
      ) : (
        <input
          type={type}
          id={name}
          name={name}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          onClick={() => setIsFocus(true)}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          ref={inputRef}
        />
      )}
    </div>
  );
};

export default CustomInput;
