import styles from "./productsDescriptions.module.scss";
import { BiPlus } from "react-icons/bi";
import { TbDotsVertical } from "react-icons/tb";
import { BiTrash } from "react-icons/bi";

// ** Hooks **
import useUserProduct from "hooks/useUserProducts";
import useAccessToken from "hooks/useAccessToken";

// ** Controller **
import { deleteProduct } from "controllers/product.controller";

const ProductsDescriptions = ({ isOpenLeftPanel, setIsOpenLeftPanel }) => {
  const { userProductData, userProductRefetch } = useUserProduct();
  const { userToken } = useAccessToken();

  const emptyProduct = {
    _id: null,
    name: "",
    description: "",
  };

  const onDelete = async (e, productId) => {
    e.stopPropagation();

    await deleteProduct(userToken, productId);

    userProductRefetch();
  };

  return (
    <div className={styles.wrapper}>
      <div style={{ display: "flex", justifyContent: "space-between" }}></div>
      <h3>Descriptions produits</h3>
      <h4>Créez, modifiez et supprimez les descriptions des produits que vous vendez pour gagner du temps.</h4>

      <p onClick={() => setIsOpenLeftPanel(emptyProduct)}>
        <BiPlus /> Ajouter une nouvelle description
      </p>

      <div className={styles.container}>
        {userProductData?.length > 0 ? (
          userProductData?.map((product) => {
            return (
              <div
                key={product._id}
                className={[styles.product, isOpenLeftPanel ? styles.openPanel : null].join(" ")}
                onClick={() => setIsOpenLeftPanel(product)}
              >
                <div className={styles.product__description}>
                  <p>{product.name}</p>
                  <p>{product.description}</p>
                </div>

                <div className={styles.modify}>
                  <TbDotsVertical />
                  <div className={styles.menu}>
                    <div
                      className={styles.menu__item}
                      onClick={async (e) => {
                        onDelete(e, product._id);
                      }}
                    >
                      <BiTrash />
                      <p>Supprimer</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p className={styles.emptyProduct}>
            Aucune description de produit n'a été créée. Cliquez sur "Ajouter une description" pour en créer une.
          </p>
        )}
      </div>
    </div>
  );
};

export default ProductsDescriptions;
