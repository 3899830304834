import styles from "./home.module.scss";

// ** Hooks **
import useAccessToken from "hooks/useAccessToken";
import useErrorModale from "hooks/useErrorModale";
import useNumberOfCreations from "hooks/useNumberOfCreations";
import { useQuery } from "react-query";

// ** Controllers **
import { fetchHistory } from "controllers/history.controllers";

// ** Components **
import FirstVisit from "components/HomeComponents/FirstVisit/FirstVisit";
import HomeCard from "components/HomeComponents/HomeCard/HomeCard";

import { Link } from "react-router-dom";
import QuickAccess from "components/HomeComponents/QuickAccess/QuickAccess";

import HistoryFile from "components/HistoryComponents/HistoryFile/HistoryFile";

import { BiRightArrowAlt } from "react-icons/bi";

const Home = () => {
  const accessToken = useAccessToken();
  const { setErrorModaleName } = useErrorModale();
  const { numberOfCreation } = useNumberOfCreations();

  const {
    data: lastActivity,
    isLoading: isLoadingLastActivity,
    error,
  } = useQuery(["files", "dashboard"], () => fetchHistory(accessToken?.userToken, 5), {
    enabled: !!accessToken,
    onError: (error) => {
      if (error?.response?.status === 401) {
        setErrorModaleName("session_expired");
      }
    },
  });

  return (
    <div className="container">
      <h1>Home</h1>
      <h6 style={{ marginBottom: "40px" }}>Bienvenue à la maison.</h6>

      {(!lastActivity || lastActivity?.length < 1) && !isLoadingLastActivity && !error && <FirstVisit />}

      {lastActivity && lastActivity.length > 0 && !error && (
        <HomeCard title={"Créations réalisées"} number={numberOfCreation} />
      )}

      {lastActivity && lastActivity.length > 0 && (
        <div className={styles.bottomWrapper}>
          <div className={styles.bottomLeft}>
            <Link to={"/generer"}>
              <h2>Accès rapide</h2>
              <BiRightArrowAlt />
            </Link>

            <QuickAccess />
          </div>
          <div className={styles.bottomRight}>
            <Link to={"/generer"}>
              <h2>Activités récentes</h2>
              <BiRightArrowAlt />
            </Link>

            <div className={styles.activities}>
              {lastActivity?.map((activity, index) => {
                return (
                  <Link key={index} to={"/historique"} state={{ activeLeftPanel: activity }}>
                    <HistoryFile key={index} file={activity} onClick={null} location="dashboard" />
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
