import { useState } from "react";

import styles from "./customDropDown.module.scss";

import { BiChevronDown } from "react-icons/bi";
import { BiX } from "react-icons/bi";

const CustomDropDown = ({ placeholder, data, value, setValue }) => {
  const [isOpenList, setIsOpenList] = useState(false);

  const handleSelect = (id, text) => {
    const newSelected = { _id: id, description: text };
    setValue(newSelected);
    setIsOpenList(false);
  };

  const clearSelected = (e) => {
    e.stopPropagation();
    setValue(null);
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={[styles.input, isOpenList ? styles.input__open : null].join(" ")}
        onClick={() => {
          setIsOpenList(!isOpenList);
        }}
      >
        {value ? (
          <p className={styles.selected}>{data.find((item) => item._id === value._id).name}</p>
        ) : (
          <p className={styles.placeholder}>{placeholder}</p>
        )}

        <div className={styles.icons}>
          {value && <BiX className={styles.icon} onClick={(e) => clearSelected(e)} />}

          <BiChevronDown />
        </div>
      </div>

      {isOpenList && (
        <div className={styles.list}>
          {data ? (
            data.map((item, index) => {
              return (
                <div
                  className={styles.list__item}
                  key={index}
                  onClick={() => {
                    handleSelect(item._id, item.description);
                  }}
                >
                  <p>{item.name}</p>
                </div>
              );
            })
          ) : (
            <p>Aucuns elements à afficher</p>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomDropDown;
