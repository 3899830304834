import styles from "./firstVisit.module.scss";

import { BiRightArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";

const FirstVisit = () => {
  return (
    <div className={styles.container}>
      <h2>Bienvenue sur Sales Copilot, l'assistant qui va booster vos discours de vente.</h2>

      <Link to={"/generer"}>
        <p>Créer votre premier discours de vente en moins de 3 clics</p>
        <BiRightArrowAlt />
      </Link>
    </div>
  );
};

export default FirstVisit;
