import { useState } from "react";

import styles from "./historyVisualisation.module.scss";

import { TbFileFilled } from "react-icons/tb";
import { BiCopy } from "react-icons/bi";
import { BiTrash } from "react-icons/bi";

import LeftPanelWrapper from "components/BasicComponents/LeftPanelWrapper/LeftPanelWrapper";

import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";

const HistoryVisualisation = ({ isModaleVisualisationOpen, setIsModaleVisualisationOpen, deleteFunction }) => {
  const [isCopyMessageDisplayed, setIsCopyMessageDisplayed] = useState(false);

  const closePanel = () => {
    setIsModaleVisualisationOpen(null);
  };

  const displayCopyMessage = () => {
    setIsCopyMessageDisplayed(true);

    setTimeout(() => {
      setIsCopyMessageDisplayed(false);
    }, 900);
  };

  const copyToClipboard = () => {
    const textWithoutMarkdown = isModaleVisualisationOpen?.content?.replace(/[*#]/g, "");
    navigator.clipboard.writeText(textWithoutMarkdown);

    displayCopyMessage();
  };

  return (
    <LeftPanelWrapper closePanel={closePanel}>
      <div className={styles.title}>
        <TbFileFilled style={{ color: isModaleVisualisationOpen?._categoryId?.color }} />
        <h2>{isModaleVisualisationOpen?._categoryId?.name}</h2>
      </div>

      {isModaleVisualisationOpen?.userContext && (
        <p className={styles.infos}>
          <span>{isModaleVisualisationOpen.userContext.split(":")[0]} :</span>
          {isModaleVisualisationOpen.userContext.split(":")[1]}
        </p>
      )}

      {isModaleVisualisationOpen?.userTarget && (
        <p className={styles.infos}>
          <span>Cible :</span> {isModaleVisualisationOpen.userTarget}
        </p>
      )}

      <div className={styles.content}>
        <div className={styles.content__modify}>
          {isCopyMessageDisplayed && <p className={styles.content__modify__message}>Copié !</p>}

          <BiCopy onClick={copyToClipboard} />
          <BiTrash onClick={() => deleteFunction(isModaleVisualisationOpen._id)} />
        </div>

        <ReactMarkdown
          children={isModaleVisualisationOpen?.content}
          className="reactMarkDown"
          remarkPlugins={[remarkGfm]}
        />
        {/* <p>{isModaleVisualisationOpen?.content}</p> */}
      </div>
    </LeftPanelWrapper>
  );
};

export default HistoryVisualisation;
