import { createContext, useState } from "react";

export const ErrorModaleContext = createContext();

const ErrorModaleProvider = ({ children }) => {
  const [errorModaleName, setErrorModaleName] = useState("");

  return (
    <ErrorModaleContext.Provider value={{ errorModaleName, setErrorModaleName }}>
      {children}
    </ErrorModaleContext.Provider>
  );
};

export default ErrorModaleProvider;
