import { useState } from "react";
import SettingsMenu from "components/SettingsComponents/SettingsMenu/SettingsMenu";
import LeftPanelWrapper from "components/BasicComponents/LeftPanelWrapper/LeftPanelWrapper";
import ProductsDescriptions from "components/SettingsComponents/ProductsDescriptions/ProductsDescriptions";
import ProductLeftPanel from "components/SettingsComponents/ProductLeftPanel/ProductLeftPanel";
import AccountManagement from "components/SettingsComponents/AccountManagement/AccountManagement";
import BillingMenu from "components/SettingsComponents/BillingMenu/BillingMenu";
import styles from "./settings.module.scss";

import PasswordManagement from "components/SettingsComponents/PasswordManagement/PasswordManagement";
import useQueryParams from "hooks/useQueryParams";

const Settings = () => {
  const [isOpenLeftPanel, setIsOpenLeftPanel] = useState(null);

  const queryParams = useQueryParams();
  const focus = queryParams.get("focus");

  const closePanel = () => {
    setIsOpenLeftPanel(null);
  };

  return (
    <div className="container">
      <div className={isOpenLeftPanel ? styles.panelOpen : styles.panelClose}>
        <h1>Réglages</h1>
        <h6>Gérez facilement vos descriptions produits ainsi que votre compte.</h6>

        <SettingsMenu focus={focus} setIsOpenLeftPanel={setIsOpenLeftPanel} />

        {(focus === "descriptions-produits" || !focus) && (
          <ProductsDescriptions isOpenLeftPanel={isOpenLeftPanel} setIsOpenLeftPanel={setIsOpenLeftPanel} />
        )}
        {focus === "mot-de-passe" && <PasswordManagement />}
        {focus === "compte" && <AccountManagement />}
        {focus === "abonnement" && <BillingMenu />}
      </div>

      {isOpenLeftPanel && (
        <LeftPanelWrapper closePanel={closePanel}>
          <ProductLeftPanel product={isOpenLeftPanel} setIsOpenLeftPanel={setIsOpenLeftPanel} />
        </LeftPanelWrapper>
      )}
    </div>
  );
};

export default Settings;
