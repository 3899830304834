import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useAccessToken from "hooks/useAccessToken";

const Redirection = () => {
  const { userToken } = useAccessToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (userToken) {
      navigate("/");
    } else {
      navigate("/connexion");
    }
  }, [navigate, userToken]);

  return;
};

export default Redirection;
