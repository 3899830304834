import styles from "./goBack.module.scss";

import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

const GoBack = ({ link }) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(link);
  };

  return (
    <div className={styles.container} onClick={goBack}>
      <BiArrowBack />
    </div>
  );
};

export default GoBack;
