import { Link, useNavigate } from "react-router-dom";
import { useState, useContext } from "react";

import { AuthContext } from "context/authContext";

import ConnexionPage from "components/ConnexionPage/ConnexionPage";
import CustomInput from "components/BasicComponents/FormsComponents/CustomInput/CustomInput";
import CustomButton from "components/BasicComponents/FormsComponents/CustomButton/CustomButton";
import CustomCheckbox from "components/BasicComponents/FormsComponents/CustomCheckbox/CustomCheckbox";
import CustomLoader from "components/BasicComponents/CustomLoader/CustomLoader";

import { handleSubmitSignup } from "controllers/user.controllers";

const Signup = () => {
  const navigate = useNavigate();
  const { setTokenChange } = useContext(AuthContext);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userConfirmPassword, setUserConfirmPassword] = useState("");
  const [generalCondition, setGeneralCondition] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <ConnexionPage>
      <form
        onSubmit={(e) => {
          handleSubmitSignup(
            e,
            userName,
            userEmail,
            userPassword,
            userConfirmPassword,
            generalCondition,
            setErrorMessage,
            setIsLoading,
            setTokenChange,
            navigate
          );
        }}
      >
        <h1>Inscription</h1>
        <h2>
          Bienvenue sur SalesCopilot, remplissez ces quelques informations et découvrez un nouveau monde de
          possibilités.
        </h2>

        <CustomInput
          label="Entrez votre nom"
          type="text"
          paddingBottom="20px"
          value={userName}
          setValue={setUserName}
        />

        <CustomInput
          label="Entrez votre adresse email"
          type="email"
          paddingBottom="20px"
          value={userEmail}
          setValue={setUserEmail}
        />

        <CustomInput
          label="Tapez un mot de passe"
          paddingBottom={userPassword?.length === 0 || userPassword?.length > 7 ? "20px" : "6px"}
          value={userPassword}
          setValue={setUserPassword}
          type="password"
        />
        {userPassword?.length > 0 && userPassword?.length < 8 && (
          <p style={{ fontSize: "12px", marginBottom: "12px" }}>Le mot de passe doit être de 8 caractères minimum.</p>
        )}

        <CustomInput
          label="Confirmez votre mot de passe"
          value={userConfirmPassword}
          setValue={setUserConfirmPassword}
          type="password"
        />

        <CustomCheckbox
          label={
            <p>
              J'accepte{" "}
              <a href="https://sales-copilot.com/conditions-generales-d-utilisation" about="_blank">
                les conditions générales d&apos;utilisation
              </a>
              .
            </p>
          }
          value={generalCondition}
          setValue={setGeneralCondition}
        />

        {!isLoading ? (
          <CustomButton
            content={"Inscription"}
            display
            style={{ width: "100%", background: "#1b1c1f", boxShadow: "none" }}
          />
        ) : (
          <CustomLoader style={{ color: "#1b1c1f", height: "30px", width: "30px" }} />
        )}

        {errorMessage && <p style={{ color: "red", fontStyle: "italic", marginBottom: "20px" }}>{errorMessage}</p>}

        <p>
          Déjà inscrit ? <Link to={"/connexion"}>Connectez-vous ici.</Link>
        </p>
      </form>
    </ConnexionPage>
  );
};

export default Signup;
