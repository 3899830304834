import { useRoutes } from "react-router-dom";

// ** Pages **
import Home from "layouts/Dashboard/Home/Home";
import Generate from "layouts/Dashboard/Generate/Generate";
import PhonePitch from "layouts/Dashboard/Generate/PhonePitch/PhonePitch";
import PersonaCustomer from "layouts/Dashboard/Generate/PersonaCustomer/PersonaCustomer";
import ProspectionMail from "layouts/Dashboard/Generate/ProspectionMail/ProspectionMail";
import CustomersTarget from "layouts/Dashboard/Generate/CustomersTarget/CustomersTarget";
import Contact from "layouts/Dashboard/Generate/Contact/Contact";
import Recueil from "layouts/Dashboard/Generate/CollectionOfNeeds/CollectionOfNeeds";
import ArgumentsListing from "layouts/Dashboard/Generate/argumentsListing/ArgumentsListing";
import ResponseToObjection from "layouts/Dashboard/Generate/responseToObjection/responseToObjection";
import LinkedinPrivateMessage from "layouts/Dashboard/Generate/LinkedinPrivateMessage/LinkedinPrivateMessage";
import ObjectionGrid from "layouts/Dashboard/Generate/ObjectionGrid/ObjectionGrid";
import Redirection from "layouts/Connexion/Redirection/Redirection";
import History from "layouts/Dashboard/History/History";
import Settings from "layouts/Dashboard/Settings/Settings";
import PaymentSucess from "layouts//Dashboard/PaymentSuccess/PaymentSuccess";

import ProtectedLayer from "components/BasicComponents/ProtectedLayer/ProtectedLayer";

const AuthRoutes = () => {
  const routes = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "generer",
      element: (
        <ProtectedLayer>
          <Generate />
        </ProtectedLayer>
      ),
    },
    {
      path: "generer/ciblage-clients",
      element: (
        <ProtectedLayer>
          <CustomersTarget />
        </ProtectedLayer>
      ),
    },
    {
      path: "generer/persona-prospect",
      element: (
        <ProtectedLayer>
          <PersonaCustomer />
        </ProtectedLayer>
      ),
    },
    {
      path: "generer/email-prospection",
      element: (
        <ProtectedLayer>
          <ProspectionMail />
        </ProtectedLayer>
      ),
    },
    {
      path: "generer/message-prive-linkedin",
      element: (
        <ProtectedLayer>
          <LinkedinPrivateMessage />
        </ProtectedLayer>
      ),
    },
    {
      path: "generer/pitch-telephonique",
      element: (
        <ProtectedLayer>
          <PhonePitch />
        </ProtectedLayer>
      ),
    },
    {
      path: "generer/contact",
      element: (
        <ProtectedLayer>
          <Contact />
        </ProtectedLayer>
      ),
    },
    {
      path: "generer/recueil",
      element: (
        <ProtectedLayer>
          <Recueil />
        </ProtectedLayer>
      ),
    },
    {
      path: "generer/arguments-a-la-volee",
      element: (
        <ProtectedLayer>
          <ArgumentsListing />
        </ProtectedLayer>
      ),
    },
    {
      path: "generer/repondre-a-une-objection",
      element: (
        <ProtectedLayer>
          <ResponseToObjection />
        </ProtectedLayer>
      ),
    },
    {
      path: "generer/tableau-d-objections",
      element: (
        <ProtectedLayer>
          <ObjectionGrid />
        </ProtectedLayer>
      ),
    },
    {
      path: "historique",
      element: <History />,
    },
    {
      path: "reglages",
      element: <Settings />,
    },
    {
      path: "paiement-reussi",
      element: <PaymentSucess />,
    },
    {
      path: "*",
      element: <Redirection />,
    },
  ]);

  return routes;
};

export default AuthRoutes;
