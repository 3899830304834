import styles from "./errorMessageDisplay.module.scss";

const ErrorMessageDisplay = () => {
  return (
    <div className={["dfCenterContainer", styles.ErrorMessageDisplay].join(" ")}>
      <p>Oups ! Nous sommes désolé, une erreur est survenue</p>
    </div>
  );
};

export default ErrorMessageDisplay;
