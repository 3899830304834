import styles from "./customButton.module.scss";

import { BiLoaderCircle } from "react-icons/bi";
import { BiCheck } from "react-icons/bi";

const CustomButton = ({
  content,
  onclick,
  disabled,
  display,
  style,
  cssClass = null,
  isLoading,
  check = null,
  color = null,
}) => {
  let colorStyle = null;

  if (color === "red") {
    colorStyle = styles.color__red;
  } else if (color === "green") {
    colorStyle = styles.color__green;
  }

  return (
    <button
      disabled={(disabled || isLoading) && true}
      className={[styles.CustomButton, display ? styles.CustomButton__display : null, colorStyle, cssClass].join(" ")}
      onClick={onclick ? (e) => onclick(e) : null}
      style={style}
    >
      {!isLoading && !check && content}
      {isLoading && (
        <div className={styles.loader}>
          <BiLoaderCircle />
        </div>
      )}
      {check && !isLoading && (
        <div className={styles.check}>
          <BiCheck />
        </div>
      )}
    </button>
  );
};

export default CustomButton;
