import styles from "./GenerationFormTitle.module.scss";
import GenerationIconBox from "components/GenerationComponents/GenerationIconBox/GenerationIconBox";
import { modelGenerationItem } from "data/modelGenerationItem";

const GenerationFormTitle = ({ Icon, title }) => {
  const actualItem = modelGenerationItem.find((item) => item.name === title);

  return (
    <div className={styles.container}>
      {<GenerationIconBox Icon={Icon} color={actualItem.color} />} <h2>{title}</h2>
    </div>
  );
};

export default GenerationFormTitle;
