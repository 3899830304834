import GenerationCard from "components/GenerationComponents/GenerationCard/GenerationCard";
import styles from "./GenerateCardWrapper.module.scss";

const GenerateCardWrapper = ({ data, Icon, title }) => {
  const titleToUpperCase = title.charAt(0).toUpperCase() + title.substring(1);

  return (
    data.length > 0 && (
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <h5>{titleToUpperCase}</h5>
          <p>{data.length}</p>
        </div>

        <div className={styles.container}>
          {data.map((item, index) => {
            const IconComponent = Icon.filter((iconLine) => iconLine.name === item.name)[0].icon;

            return <GenerationCard key={index} item={item} Icon={<IconComponent />} />;
          })}
        </div>
      </div>
    )
  );
};

export default GenerateCardWrapper;
