import axios from "axios";

import { apiUrl } from "../configs/apiUri";
import { checkSignup } from "utils/checkData/checkSignup/checkSignup";
import { checkLogin } from "utils/checkData/checkLogin/checkLogin";
import checkMail from "utils/checkData/checkMailFormat/checkMailFormat";
import Cookies from "js-cookie";

export const handleSubmitSignup = async (
  event,
  userName,
  userEmail,
  userPassword,
  userConfirmPassword,
  generalCondition,
  setErrorMessage,
  setIsLoading,
  setTokenChange,
  navigate
) => {
  event.preventDefault();
  setErrorMessage("");

  if (!checkSignup(userName, userEmail, userPassword, userConfirmPassword, generalCondition, setErrorMessage)) {
    return;
  }

  setIsLoading(true);

  const data = {
    userName: userName,
    userEmail: userEmail,
    userPassword: userPassword,
  };

  try {
    const callServer = await axios.post(`${apiUrl}/user/signup`, data);

    if (callServer.data) {
      Cookies.set("_access", callServer.data.accessToken, { expires: 30 });
      Cookies.set("_refresh", callServer.data.refreshToken, { expires: 30 });

      setTokenChange((prevState) => !prevState);

      navigate("/");
    }
  } catch (error) {
    console.error(error?.response?.data?.error);
    setErrorMessage(error?.response?.data?.error);
  }

  setIsLoading(false);
};

export const handleSubmitLogin = async (
  event,
  userEmail,
  userPassword,
  setErrorMessage,
  setIsLoading,
  setTokenChange,
  navigate
) => {
  event.preventDefault();
  setErrorMessage("");

  if (!checkLogin(userEmail, userPassword, setErrorMessage)) {
    return;
  }

  setIsLoading(true);

  const data = {
    userEmail: userEmail,
    userPassword: userPassword,
  };

  try {
    const callServer = await axios.post(`${apiUrl}/user/login`, data);

    if (callServer.data) {
      Cookies.set("_access", callServer.data.accessToken, { expires: 30 });
      Cookies.set("_refresh", callServer.data.refreshToken, { expires: 30 });

      setTokenChange((prevState) => !prevState);

      navigate("/");
    }
  } catch (error) {
    console.error(error?.response?.data?.error);
    setErrorMessage(error?.response?.data?.error);
  }

  setIsLoading(false);
};

export const getMail = async (accessToken) => {
  const { data } = await axios.get(`${apiUrl}/user/email`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data;
};

export const updateEmail = async (accessToken, newEmail, setMessage) => {
  if (!checkMail(newEmail)) {
    setMessage({ type: "error", message: "Veuillez entrer une adresse mail valide." });
    return;
  }

  const { data } = await axios.put(
    `${apiUrl}/user/email`,
    { userEmail: newEmail },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (data) {
    setMessage({ type: "success", message: "Email mis à jour avec succès !" });

    setTimeout(() => {
      setMessage(null);
    }, 2000);

    return data;
  } else {
    setMessage({ type: "error", message: "Un problème est survenu lors de la mise à jour de votre email." });
  }
};

export const updatePassword = async (accessToken, oldPassword, newPassword, newPasswordConfirm, setMessage) => {
  if (oldPassword === newPassword) {
    setMessage({ type: "error", message: "Veuillez entrer un nouveau mot de passe." });
    return;
  }

  if (newPassword.length < 8 || newPassword.length > 30) {
    setMessage({ type: "error", message: "Le mot de passe doit faire au moins 8 caractères." });
    return;
  }

  if (newPassword !== newPasswordConfirm) {
    setMessage({ type: "error", message: "Les mots de passe ne correspondent pas." });
    return;
  }

  const { data } = await axios.put(
    `${apiUrl}/user/password`,
    { userOldPassword: oldPassword, userNewPassword: newPassword },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (data) {
    setMessage({ type: "success", message: "Mot de passe mis à jour avec succès !" });

    setTimeout(() => {
      setMessage(null);
    }, 2000);

    return data;
  } else {
    setMessage({ type: "error", message: "Un problème est survenu lors de la mise à jour de votre mot de passe." });
  }
};

export const getNumberOfCreation = async (accessToken) => {
  const { data } = await axios.get(`${apiUrl}/user/number-of-creations`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data?.numberOfCreations;
};

export const getUserStatut = async (accessToken) => {
  const { data } = await axios.get(`${apiUrl}/user/statut`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data?.statut;
};
